.investment-content {
    width: 800px;
    max-width: 100%;
}

.investment-content .single-investment {
    padding-bottom: 24px;
    padding-top: 24px;
    border-bottom: 1px solid #98A2B3;
}

.investment-content .single-investment:last-child {
    border-bottom: 0;
}