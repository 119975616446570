.repo {
    display: flex;
    flex-wrap: wrap;
    min-height: 250px;
    padding: 3% 1% 0 1%;
    margin: 0% 8%;
    margin-top: -210px;
    background-color: white;
}

main.cust-landing{
    overflow-x: unset;
}

.cust-landing .siteload{
    min-height: 400px
}


.repo a{
    transition: transform .3s;
    width: 25%;
    justify-content: center
}

.repo-block{
    word-break: break-word;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
}

.repo .card-title{
    margin-bottom: 0.2rem
}

.repo img{
    width: 80px;
    height: auto;
}

.repo-desc{
    text-align: center;
    color: #4A4A4A;
    font-size: 14px
}

.repodiv {
    position: relative;
    min-height: 450px;
}
  
  .repo a:hover {
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }

  .repo a:hover .card-title {
    color: #ef3e23
 }



@media (max-width: 599px){
    .repo{
        flex-direction: column;
        padding: 5% 1%;
        margin: 0% 4%;
        margin-top: -170px;
    }
    .repo-block{
        width: 100%;
        margin-bottom: 20px;
        padding: 0 10px
    }
    .repo a{
        width: 100%;
        justify-content: center
    }
    .repodiv {
        min-height: 0;
    }
    
}



@media (max-width:900px) and (min-width:600px) {
    .repo{
        padding: 3% 1% 0 1%;
        margin: 0% 8%;
        margin-top: -180px
    }
    .repo img {
        width: 65px;
    }

    .repo-desc {
        
        font-size: 12px;
    }
}


@media (max-width: 330px){
    .repo{
      
        margin-top: -190px;
    }

    .repo img {
        width: 75px;
    }
    
}
@media (max-width:760px) and (min-width:601px) {


.repodiv .contact_title {
    padding: 1.85% 9.35% 4.35% 7.35%;
 
}
}