::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */

    font-family: 'MuseoSans_300' !important;

}

::-moz-placeholder {
    /* Firefox 19+ */

    font-family: 'MuseoSans_300' !important
}

:-ms-input-placeholder {
    /* IE 10+ */

    font-family: 'MuseoSans_300' !important
}

:-moz-placeholder {
    /* Firefox 18- */

    font-family: 'MuseoSans_300' !important
}

.pretty-print {
    margin-top: 1em;
    margin-left: 20px;
    font-family: monospace;
    font-size: 13px;
}

.collapse-button {
    height: 10px;
}

.html-tag {
    color: rgb(136, 18, 128);
}

.button {
    user-select: none;
    -webkit-user-select: none;
    cursor: pointer;
    display: inline-block;
    margin-left: -10px;
    width: 10px;
    background-repeat: no-repeat;
    background-position: left top;
    vertical-align: bottom;
}

.product_page .popup_business {
    position: static;
    width: 100%
}

.truncate_expand p,
.truncate_expand span {
    font-family: 'MuseoSans_300' !important
}

.product_page .popup_business .card_contact {
    width: 100%;
}

.testimonial_header_div1 div:first-child {
    display: flex;
    align-items: center;
}


.head {
    background-color: white;
    padding-bottom: 160px;
}

.getfundpage .what_we div p {
    font-family: 'MuseoSans_300' !important
}

.homecards .card_container .card {
    min-height: 0
}

.btn-secondary.focus,
.btn-secondary:focus {
    box-shadow: none
}

.btn.focus,
.btn:focus {
    box-shadow: none
}

.btn-secondary.focus,
.btn-secondary:focus {
    box-shadow: 0 0 0 0 rgba(108, 117, 125, .5) !important;
}


.title p {
    font-family: 'MuseoSans_300' !important
}

.slick-next,
.slick-prev {
    visibility: hidden;
}

a:hover {
    text-decoration: none !important
}

.header button {
    width: auto !important
}

.card {

    box-shadow: 0px 0px 5px 1px rgba(193, 192, 192, 0.75) !important;
}

.title {
    margin: 7% 0;
    padding: 0 3%;
    text-align: center;
    font-size: 19px;
    color: #141744;
    font-weight: 500;
}

.rehbar_logo img {
    height: 50px;
}

.home_title_image img {
    height: 170px;
}

.home_title_image {
    display: flex;
    justify-content: center;
    /* padding: 0 5%; */
}

.card {
    margin: 5% 0;
    text-align: center;
    box-shadow: 0px 0px 5px 1px rgb(193, 192, 192)
}

.card_container {
    padding: 0 3%;
    position: relative;
    bottom: 105px;
    margin-bottom: -26%;
}


.card button {
    width: 50%;
    align-self: center;
    background-color: #EF3E23;
    color: white
}

.card button:hover {

    box-shadow: 0px 0px 1px 1px #EF3E23 !important;
}

.card-text {
    color: grey;
    font-family: MuseoSans_300 !important;
}

.card-title {
    color: #141744;
    font-weight: 500;
    font-size: 20px;
    font-family: 'MuseoSans_700' !important
}

.deals_executed .card-title {
    font-weight: 500;



}

.testimonials .card-title {
    font-weight: 500;



}

.deals_executed .card-title {
    font-weight: 500;



}

.apply_fund_homepage .card-title {
    font-weight: 500;



}

.card-body {
    padding: 1rem !important
}

main {
    background-color: #f0f0f0;
    overflow-x: hidden
}

.milestone {
    display: flex;
    justify-content: center;
    padding: 5% 0;
}

.milestone_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #141744;
    margin-left: 6%;
}

.milestone_text_number {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: -10px;
}

.milestone_img img {
    height: 50px;
}

.contact_homepage button {
    background-color: white;
    border-color: #EF3E23;
    color: #EF3E23;
    width: 47%;
    font-weight: 600;
    margin: 5% 0;
}

.contact_homepage button:hover {
    background-color: #EF3E23;
    color: white;

}

.contact_homepage {
    background-color: white;
    padding: 7px 4%;
}

.contact_homepage .card-title {
    margin-bottom: 0.5rem;
    padding-top: 30px;
}

.footer .rehbar_logo {
    display: flex;
    justify-content: center;
    padding: 5% 0;

}

.footer .rehbar_logo img {
    margin-left: 0
}

.footer {
    background-color: white;
    height: 220px;
}

.apply_fund_homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.apply_fund_homepage_button {
    display: flex;
    justify-content: space-between;
    width: 90%
}

.apply_fund_homepage_button div {
    width: 55%
}


.apply_fund_homepage_button button {
    background-color: #f0f0f0;
    border-color: #EF3E23;
    color: #EF3E23;
    width: 160px;
    font-weight: 600;
    margin: 5% 0;
}

.apply_fund_homepage_button button:hover {
    background-color: #EF3E23;
    color: #f3f3f3;

}


.testimonials {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0% 10px;
    padding: 10px;
    bottom: 200px;
    position: relative;
    margin-bottom: -50%;
}

.testimonial_header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 4% 4% 0% 3%;
}

.testimonial_div {
    width: 100%;

}

.profile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 2;
    margin-left: 5%
}

.profile .card-title {
    font-weight: 400;
    margin-bottom: 0px
}

.profile .card-text {
    font-weight: bold
}

.quote {
    position: absolute;
    right: 5%;
    z-index: 1;
}



.testimonial_footer {
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin: 10px auto;
    align-items: center;
}

.testimonial_footer img {
    height: 50px;
}

.testimonial_arrow {
    display: flex;
    width: 20%;
    justify-content: space-between;
    margin: 0 5px;

}

.testimonial_arrow img {
    height: 20px
}

.get_funded_footer {
    padding: 2px !important
}

.footer_link {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

}

.footer_link div {
    padding: 2px 3%;
    text-align: center;
    color: #141744;
}

.social_media {
    display: flex;


}



.social_media img {
    height: 25px
}

.deals_carousel img {
    height: 60px;
    margin: 0 auto;
    width: 100px;
    object-fit: contain
}

.deals_executed {
    margin: 2% 0% 0% 0;
}

.deals_executed .slick-dots {
    position: static
}

.deal_title {
    text-align: center;
    width: 100%;
}

.footer_home {
    background-color: white;
}

.navbar_mobile {
    text-align: center
}

.navbar_mobile div {
    padding: 15px 15px;
    color: #141744;
}

.navbar_mobile div:hover {
    color: #ef3e23
}

.navbar_mobile div .pr_menu div {
    padding: 10px 0
}

.navbar_mobile .about_header .pr_menu {
    padding: 0;
    min-width: 160px;
    left: -30%;
}

.navbar_mobile .about_header2 .pr_menu {
    padding: 0;
    min-width: 160px;
}

.about_header {
    position: relative;
}

.about_header2 {
    position: relative;
}

.navbar_mobile .navbar_mobile div:hover {
    color: #ef3e23
}

.card button {
    font-weight: 600
}

.testimonial_header_div1 {
    width: 100%;
    display: flex;
}

.testimonial_header_div1 .profile .card-title {
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 0 !important
}

.testimonial_header_div1 .profile .card-text {
    font-size: 15px;
    margin-bottom: 0
}

.testimonial_header_div1 .profile {
    justify-content: center
}

.testimonial_header_div1 img {
    margin-left: 10px;
    border-radius: 50%;
    object-fit: cover;
    height: 90px;
    width: 90px;
}

.testimonial_desc {
    height: 90px;
    overflow-y: auto;
    margin: 2% 8% 0% 1%;
    padding-right: 10px
}

.testimonial_desc p {
    color: grey;
    font-family: MuseoSans_300 !important
}

.deals_executed .slick-dots li button:focus:before,
.deals_executed .slick-dots li button:hover:before {
    opacity: .25;
}

.deals_executed .slick-dots li.slick-active button:before {
    opacity: .75 !important;
    color: #000;
}

@media screen and (min-width: 760px) and (max-width: 850px) {
    .product_page .products_div {
        bottom: 160px;
        margin-bottom: -150px;
    }

    .fund_title_text p {
        line-height: 150%;
        font-family: MuseoSans_700 !important
    }

    .header .navbar_mobile div {
        font-size: 11px
    }

    .header .rehbar_logo img {
        height: 45px
    }

    .products_div .card_contact_container {
        margin: 4% 0
    }

    .foot .foot_link_div {
        width: 85%
    }

    .foot .rehbar_logo img {
        height: 40px;
    }

    .head {
        padding-bottom: 18% !important
    }

    /* .homepagediv .invest_business{
        height: auto
    } */
    .homepagediv .homecards {
        margin-bottom: -12%;
    }

}

@media screen and (min-width: 901px) and (max-width:1399px) {
    .invest_bottomcard {
        padding: 0 18% !important
    }
}

@media screen and (min-width: 767px) and (max-width: 900px) {
    .rec_fund .deals_executed {
        margin: 0% 0% 0% 0 !important;
    }

    .navbar_mobile div {
        padding: 0px 10px;
        font-size: 9px;
    }

    .navbar_mobile div:hover {
        color: #ef3e23
    }

    .footer_links a div {
        padding: 8px 6px !important;
        font-size: 10px !important;
    }

    .footer_links a {
        margin: 3% 0 3% 0%;
    }

    .invest_button {
        margin-top: 23% !important;
    }

    .home_title_image {
        align-items: center !important;
    }

    .invest_button img {
        height: 250px !important;
    }

    .invest_button .card-title {
        font-size: 22px !important;
    }

    .invest_button_div {
        height: auto !important;
    }

    .invest_why img {
        height: 180px !important;
        margin-left: 15px;
    }

    .invest_card p {
        font-size: 15px !important;
    }

    .fund_title {
        margin-top: 25% !important;
        padding: 0 0 !important;
    }

    .fund_title_text {
        padding-left: 5% !important;
        font-size: 25px !important;
    }

    .fund_testimonials .card_container {
        bottom: 105px !important;
        width: 71.5% !important;
    }

    .fund_grey .card_container {
        bottom: 105px !important;
    }

    .deal_title {
        font-size: 25px !important;
    }

    .homecards .card-title {
        font-size: 19px !important;
    }

    .card-title {
        font-size: 21px !important;
    }

    .fund_title_card img {
        height: 250px !important;
    }

    .what_we_subhead {
        font-size: 22px !important;
    }

    .invest_bottomcard {
        padding: 3% 18% !important;
    }

    .contact_title {
        height: 225px !important;
    }

    .product_page .contact_title {
        padding: 2.5% 7.5% !important;
    }

    .contact_form {
        bottom: 170px !important;
    }

    .faq_button2 div {
        font-size: 0.8rem !important;
    }

    .deals_carousel img {
        height: 70px !important
    }

    .intro {
        padding: 2.5% !important
    }

    .intro_list {
        padding: 0 2.5% !important
    }

    .home_title_image img {
        height: 300px !important;
    }

    .invest_button .home_title_image img {
        height: 250px !important;
    }

    /* .card_container p {
            font-size: 15px!important;
        } */
    .milestone_img img {
        height: 50px !important;
    }

    .milestone_text {
        font-size: 17px !important;
    }

    .milestone_text_number {
        font-size: 22px !important;
        margin-bottom: -5px !important;

    }

    .testimonial_arrow {
        width: 15% !important;
    }

    .title {
        padding: 0 6% !important;
        font-size: 25px !important;
    }

    .card_container {
        padding: 0 3% !important;
    }

    .homepagediv .homecards .card {

        padding: 2% 2% !important;
    }

    .card_container {

        padding: 0 3% !important;
    }

    .homecards .card_container .card {
        min-height: 250px !important;
    }

    .footer_home {
        padding: 0 9% !important;
    }

    .accordion__item .sub .card-title {
        font-size: 15px !important;
    }

}

@media screen and (min-width: 400px) and (max-width: 499px) {
    .homepagediv .homtestimonial {
        bottom: 425px;
        margin-bottom: -115%;
    }

}

@media screen and (min-width: 901px) and (max-width: 1150px) {
    .getfundpage .fund_testimonials {
        margin: 15% 0 13% 0;
    }

    .getfundpage .fund_testimonials .card_container {
        margin-bottom: -16%
    }

    .navbar_mobile div {
        padding: 15px 11px;
        font-size: 12.5px;
    }

    .fund_grey .card_container {
        bottom: 140px !important;
    }

    .footer_links a {
        font-size: 16px;
        margin: 3% 0 3% 0%;
    }

    .invest_button {
        margin-top: 18% !important;
    }

    .products_div {
        bottom: 210px !important;
    }

    .faq .contact_title {
        padding: 3.25% 7.75% !important;
    }

    .contact_form {
        bottom: 220px !important;
    }

    .homecards .card-title {
        font-size: 19px
    }

    .footer_links a div {
        padding: 5px 15px;
    }
}


/* responsive */
@media (max-width:500px) {
    ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */

        font-size: 12px
    }

    ::-moz-placeholder {
        /* Firefox 19+ */

        font-size: 12px
    }

    :-ms-input-placeholder {
        /* IE 10+ */

        font-size: 12px
    }

    :-moz-placeholder {
        /* Firefox 18- */

        font-size: 12px
    }

    .profile {
        width: 65%
    }

    .testimonial_header_div1 div:first-child {

        width: 30%
    }

    .testimonial_header_div1 .profile div:first-child {
        width: auto
    }

    .homepagediv .invest_business {
        margin-bottom: 117%
    }

    .fund_newsletter {
        padding: 0 3%
    }

    .testimonial_header_div1 .profile .card-text {
        font-size: 12px
    }

    .fund_testimonials .profile .card-title {
        font-size: 12px
    }

    .header .navbar {
        padding: 10px 1rem
    }

    .invest_business .deals_executed .deal_title {
        font-size: 24px;
        padding: 0 2%;
        margin-bottom: 5%
    }

    /* .deal_title{
          font-size: 25px;
          padding: 0 2%
      } */
    .deals_executed .slick-dots {
        margin-top: 7.5%
    }


    .homepagediv .rec_fund .deals_executed .card-title {
        margin-bottom: 2rem
    }

    .homtestimonial {
        bottom: 550px;
        margin-bottom: -165%;
    }

    .popup .popup_business .card_contact {
        max-height: 2000px
    }

    .testimonial_header_div1 {

        justify-content: space-around;
        align-items: center;
    }

    .about_card .testimonial_header_div1 img {
        height: 85px;
        width: 85px;
    }

    .testimonial_header_div1 img {
        height: 90px;
        width: 90px;
    }

    .testimonial_header {
        padding: 4% 0% 0% 0%
    }

    .note {
        font-size: 10px
    }

    .profile .card-text {
        font-size: 12.5px
    }

    .popup_business .card_contact .card-title {
        font-size: 13px
    }

    .product_page .accordion .accordion__arrow {
        width: 25px
    }

    main .homepagediv .card_container .card {
        margin: 2% 1%;
        min-height: 140px;
        display: flex;
        justify-content: center;

    }

    .homepagediv .rec_fund {
        padding: 17% 0 !important;
        margin-bottom: -5%;
    }

    .milestone_main {
        padding-bottom: 5%
    }

    .contactpage .contact_form .contact_address {
        padding: 7.5% 0;
    }

    /* div.footer_links a:nth-child(1){
        width: 22%
    } */
    div.footer_links a:nth-child(2) {
        width: 33%
    }

    div.footer_links a:nth-child(3) {
        width: 33%
    }

    /* div.footer_links a:nth-child(7) {
        width: 60%
    } */

    .footer_links a {
        font-size: 13px;
        width: 33%
    }

    .about_page .features .benefit_list .about_p {
        padding: 0 5%
    }

    .about_page .features .benefit_list .about_p p {
        text-indent: -1.0em;
    }
}

@media (max-width: 767px) {

    .apply_fund_homepage_button button {

        width: 120px;

    }

    .testimonial_desc {
        height: 120px;
    }

    .title p {
        font-family: 'MuseoSans_500' !important
    }

    .card-title {
        font-weight: 600;

    }

    .deals_executed .card-title {
        font-weight: 600;



    }

    .testimonials .card-title {
        font-weight: 600;
        font-size: 25px
    }

    .deals_executed .card-title {
        font-weight: 600;



    }

    .apply_fund_homepage .card-title {
        font-weight: 600;



    }


    .apply_fund_homepage {
        margin-top: 10%
    }

    .slick-dots li button:before {
        font-size: 9px
    }

    .popup_close_bus {
        bottom: 10px !important;
        left: 5px !important;
    }

    .rehbar_logo img {
        margin-top: 10%
    }

    .deals_carousel img {
        height: 60px
    }

    .popup_business {
        width: 95%;
        top: 15%
    }


    .invest_business {
        margin-bottom: 65%
    }

    .foot {
        padding-bottom: 5%;
    }

    .social_media div {
        width: 33%;
        display: flex;
        justify-content: center;
    }

    .social_media {
        margin: 5% 0;
        padding: 0 5%;
    }

    .deals_executed {
        margin: 5% 0% 0% 0;
    }



}

@media(min-width:1151px) {
    .navbar_mobile div {
        padding: 15px 20px;
        color: #141744;
        font-size: 15px;
    }

    .navbar_mobile div:hover {
        color: #ef3e23
    }

}

@media (min-width:500px) {
    .product_page .popup .card_contact {
        max-height: 500px;
    }

    body .testimonials .testimonial_div .testimonial_desc::-webkit-scrollbar {
        width: 3px;
        background-color: #eeeeee;
    }

    body .testimonials .testimonial_div .testimonial_desc::-webkit-scrollbar-thumb {
        background-color: #aaaaaa;
    }

    body .testimonials .testimonial_div .testimonial_desc::-webkit-scrollbar-track {
        background-color: #dedbdb;
    }

}

@media (min-width: 768px) {
    .testimonial_div .profile .card-title {
        font-size: 25px
    }

    .testimonial_header_div1 {
        width: 100%
    }

    .milestone_textleft {
        width: 100%
    }

    .contact_homepage .card-title {
        font-size: 27px
    }

    .milestone_img img {
        height: 55px;
    }

    .header {
        padding: 0 2%
    }

    .card-title {
        font-size: 22px;
    }

    .homecards .card-title {
        padding: 0 3%;
    }

    .homepagediv .homecards .secondcard {
        padding: 0 21%;

    }

    .rec_fund .deals_executed .slick-slider {
        margin-top: 4%;
    }

    .card button {
        max-width: 170px;
        border-radius: 0.4rem
    }

    .fund_newsletter .card button {
        max-width: 300px;
        cursor: pointer;
    }

    .social_media div {
        margin-left: 6%;
    }

    .social_media {
        margin-bottom: 5%
    }

    .flex {
        display: flex;
        flex-direction: row !important
    }

    .flex img {
        margin-right: 10px
    }

    .apply_fund_homepage_button div {
        width: 65%
    }

    .head {
        background-color: white;
        padding-bottom: 2%;
    }

    .navbar-collapse {
        justify-content: flex-end !important;

    }






    .rehbar_logo {
        width: 15%
    }

    .footer_div2 {
        width: 85%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .footer {
        display: flex;
        padding: 0 16.5%;
        height: auto;
    }



    .footer_link {

        justify-content: flex-end;
        font-size: 16px;
    }

    .footer_link div {
        padding: 2px 3%;
    }

    .footer .rehbar_logo {
        padding: 2% 0
    }

    .footer .rehbar_logo img {
        height: 65px;
    }

    .social_media {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding: 0 10px
    }


    .social_media img {
        height: 20px;
    }

    .contact_homepage {
        background-color: white;
        padding: 0 14%;
    }

    .contact_homepage_div2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .contact_homepage_div2 a {
        width: 20%
    }

    .contact_homepage_div2 .card-text {
        font-size: 18px;
        padding-right: 4%;
        width: 69%
    }

    .contact_homepage button {

        width: 100%;

    }

    .milestone_main {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        font-size: 20px
    }

    .milestone_main .milestone {
        width: 30%;
        flex-direction: column;
        padding: 3% 0;
        align-items: center;

    }

    .milestone_text {
        margin-left: 0;
        font-size: 22px;
        margin-top: 15px;
    }

    .card_container {
        display: flex;
        justify-content: center;
        padding: 0 6%;
        margin-bottom: -8%;
    }

    .card_container .card {
        margin: 0 1%;
        /* min-height: 300px; */
        justify-content: space-evenly;
        width: 100%;
        padding: 3% 4% !important;

    }

    .card_container p {
        font-size: 17px
    }


    .home_title_image img {
        height: 100%;
        width: 70%;
        padding: 0 5%;
        margin: 2% 0;
        object-fit: cover;
    }

    .title {
        padding: 0 12%;
        font-size: 33px;
        margin: 2% 0
    }

    .navbar-expand-md {

        padding: 1% 5% !important;
    }

    .rec_fund .deals_executed {
        margin: 10% 0% 0% 0;
    }

    .deals_executed {
        margin: 4% 0% 0% 0;
        padding: 0 20%;
    }

    .deal_title {
        font-size: 35px
    }

    .invest_business {
        background-color: white;
        /* height: 550px */
    }

    .testimonials {
        width: 75%;
        margin: 5% auto;
        padding: 2%;
        bottom: 190px;
        margin-bottom: -20%;


    }

    .testimonials .card-title {
        font-size: 35px
    }

    .apply_fund_homepage_button .card-title {
        font-size: 25px
    }

    .testimonial_arrow {
        width: 7%
    }

    .quote {
        position: relative;
        right: 5%;
        z-index: 1;
    }

    .quote img {
        height: 100px
    }

    .apply_fund_homepage_button {

        width: 48%;
        align-items: center;
        margin: 4.7% 0 3.7% 0;
    }

    .rehbar_logo img {
        height: 75px;

    }


}

@media (min-width:501px) and (max-width : 766px) {
    .rec_fund {
        margin-top: 25%
    }

    .homepagediv .homecards {
        margin-bottom: -22%;
        /* bottom: 65px */
    }

    .homepagediv .invest_business {
        margin-bottom: 15%
    }

    .homepagediv .rec_fund .deals_executed .card-title {
        margin-bottom: 2rem
    }

    .getfundpage .fund_grey .card_container {
        bottom: 120px
    }

    .getfundpage .fund_testimonials .card_container {
        bottom: 155px
    }

    .getfundpage .fund_testimonials {
        margin: 25% 0 17% 0
    }

    .getfundpage .fund_title {
        margin-top: 33%
    }

    .getfundpage .fund_title_card {
        flex-direction: row;
    }

    .getfundpage .fund_newsletter button:after {
        margin-left: -18%;
    }
}

@media (max-width: 767px) {

    .footer_link div {
        width: 25%;
        padding: 2px 3px;
    }

    /* .product_page .popup .card_contact{
    max-height: auto
} */
}

@media (min-width: 2000px) {
    .title {
        padding: 0 35%;
    }

    .card_container {

        padding: 0 30%;
        margin-bottom: -4%;
    }

    .milestone_main {
        padding: 0 25%;
        margin-bottom: 2%;
    }

    .contact_homepage {
        padding: 0 30%
    }

    .testimonials {
        margin-bottom: -17%;
        width: 50%;
    }

    .rec_fund .deals_executed {
        margin: 12% 0% 0% 0;
    }

    .apply_fund_homepage_button {
        padding: 0 8%
    }

    .getfundpage .fund_title {
        margin-top: 9%;
        padding: 0 21.5%;
    }

    .getfundpage .what_we {
        padding: 2% 6%;
    }

    .getfundpage .fund_grey {
        padding: 4% 25% !important;
    }

    .getfundpage .fund_div {
        padding: 0 25%;
    }

    .getfundpage .fund_grey .card_container {
        width: 79.5%
    }

    .testimonials .testimonial_desc {
        height: 145px;
    }

    .getfundpage .fund_title_text {

        padding: 0 14%;
        font-size: 42px
    }

    .getfundpage .fund_title_card img {
        height: 350px;
    }

    .getfundpage .what_we_head {
        padding-top: 3%
    }

    .getfundpage .fund_grey {
        margin-top: 9%;
    }

    .getfundpage .fund_grey .card_container {
        bottom: 210px;
        margin-bottom: -13%;
    }

    .getfundpage .fund_testimonials .card_container {
        bottom: 315px;
        margin-bottom: 1% !important;
    }

    .getfundpage .fund_deals_executed .deals_executed {
        margin: 4% 0 0 0
    }

    .getfundpage .fund_testimonials {
        margin: 7% 0
    }

    .getfundpage .fund_testimonials_card_bottom {
        top: 205px
    }

    .getfundpage .fund_testimonials .testimonials {
        width: 57%;

    }

    .getfundpage .fund_testimonials .card_container .card {
        margin: 0 6%;
        margin-bottom: -11%;
    }

    .getfundpage .invest_bottomcard {
        padding: 0 21.35%;
        margin-top: 6%;
    }

    .faq .sub .accordion__arrow {
        margin-top: -1% !important;
    }

    .faq .faq_button2 {
        width: 20%
    }

    .faq .contact_title {
        padding: 1.7% 11.75% !important;
    }

    .about_page .contact_title {
        padding: 2% 8% !important;
    }

    .faq .accordion__title {
        padding: 12px 0px 7px 0 !important
    }

    .faq .accordion {
        padding: 0 1%;
    }

    .faq .accordion__body {
        padding: 0 0% 3% 1.5% !important;
    }

    .faq .contact_form {
        width: 76.5% !important;
    }

    .contactpage .contact_title {
        padding: 2% 23%;
    }

    .contactpage .contact {
        padding: 0 21.5%;
    }

    .about_page .intro {
        margin: -1% auto;
        width: 53%;
        bottom: 180px
    }

    .about_page .about_padding {
        padding: 0 21.5%;
    }

    .about_page .products {
        padding: 0% 11.5% !important;
    }

    .about_page .rerl_port {
        padding: 7px 11.5% 3% 11.5%;
    }

    .about_table_div {
        padding: 0 2.85%;
    }

    .about_page .about_management {
        padding: 15px 22.5% 2.5% 22.5%
    }

    .foundation {
        padding: 3% 22% 2.5% 22% !important;
    }

    .header {
        padding: 0 20%
    }

    .product_page .contact_title {
        padding: 1.5% 23.5% !important;
    }

    .product_page .products_div {
        width: 53%
    }

    .foot {
        padding: 0 20%
    }

}

@media (min-width:1400px) and (max-width : 1920px) {
    .investpage .invest_button {
        padding: 0 6%;
        margin-top: 11%;
    }

    .investpage .invest_why1 {
        padding: 7% 7% 3% 7% !important;
    }

    .investpage .invest_why {
        padding: 4% 11.5% !important;
    }

    .title {
        padding: 0 20%;
    }

    .card_container {
        bottom: 112px;
        padding: 0 5%;
        margin-bottom: -5%;
    }

    .contact_homepage {
        padding: 0 17%
    }

    .rec_fund .deals_executed {
        margin: 12% 0% 0% 0;
    }

    .apply_fund_homepage_button {
        padding: 0 8%
    }

    .getfundpage .fund_title {
        margin-top: 13%;
        padding: 0 6.5%;
    }

    .getfundpage .what_we {
        padding: 2% 6%;
    }

    .getfundpage .fund_grey {
        padding: 4% 12% !important;
    }

    .getfundpage .fund_div {
        padding: 0 11.5%;
    }

    .getfundpage .fund_grey .card_container {
        width: 79.5%
    }

    .getfundpage .fund_div {
        padding: 0 12%;
    }

    .getfundpage .fund_title_text {

        padding: 0 14%;
        font-size: 42px
    }

    .getfundpage .fund_title_card img {
        height: 350px;
    }

    .getfundpage .what_we_head {
        padding-top: 3%
    }

    .getfundpage .fund_grey {
        margin-top: 9%;
    }

    .getfundpage .fund_grey .card_container {
        bottom: 130px;
        margin-bottom: -12%;
    }

    .getfundpage .fund_testimonials .card_container {
        bottom: 200px;
        margin-bottom: 1% !important;
    }

    .getfundpage .fund_deals_executed .deals_executed {
        margin: 4% 0 0 0
    }

    .getfundpage .fund_testimonials {
        margin: 9% 0
    }

    .getfundpage .fund_testimonials_card_bottom {
        top: 205px
    }

    .getfundpage .fund_testimonials .testimonials {
        width: 70%;

    }

    .getfundpage .fund_testimonials .card_container .card {
        margin: 0 6%;
        margin-bottom: -16%;
    }

    .getfundpage .invest_bottomcard {
        padding: 0 21.35%;
        margin-top: 17%;
    }

    .faq .sub .accordion__arrow {
        margin-top: -1% !important;
    }

    .faq .faq_button2 {
        width: 20%
    }

    .faq .contact_title {
        padding: 1.7% 11.75% !important;
    }

    .about_page .contact_title {
        padding: 2% 8% !important;
    }

    .faq .accordion__title {
        padding: 12px 0px 7px 0 !important
    }

    .faq .accordion {
        padding: 0 1%;
    }

    .faq .accordion__body {
        padding: 0 0% 3% 1.5% !important;
    }

    .faq .contact_form {
        width: 76.5% !important;
    }

    .contactpage .contact_title {
        padding: 2% 11.75%;
    }

    .contactpage .contact {
        padding: 0 9.8%;
    }

    .about_page .intro {
        margin: -1% auto;
        width: 77%;
        bottom: 180px
    }

    .about_page .about_padding {
        padding: 0 8%;
    }

    .about_page .products {
        padding: 0% 11.5% !important;
    }

    .about_page .rerl_port {
        padding: 7px 11.5% 3% 11.5%;
    }

    .about_table_div {
        padding: 0 2.85%;
    }

    .about_page .about_management {
        padding: 15px 11.5% 2.5% 11.5%;
    }

    .header {
        padding: 0 7%
    }

    .product_page .contact_title {
        padding: 2% 11.5% !important;
    }

    .product_page .products_div {
        width: 77%
    }

    .foot {
        padding: 0 12%
    }


}

/* tab */

@media (min-width: 700px) and (max-width: 800px) {

    .product_page .benefit_list_item p {
        text-indent: -1em;
    }

    .about_page .about_p p {
        text-indent: -1.05em;

    }

    .contactpage .contact_form {

        margin-bottom: -170px;
    }

    .about_page .products .clients .deals_carousel img {
        height: 40px !important
    }
}