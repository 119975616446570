.assets_page {
    padding: 0 12%;
}

.head-banner {
    margin: 96px 0;
}

.guidelines {
    row-gap: 48px;
    margin-bottom: 96px;
}

.single-guide .link {
    display: flex;
    align-items: center;
    column-gap: 13px;
    text-decoration: none;
}

.single-guide .link:hover {
    opacity: 0.8;
    color: #EF3E23;
}

.single-guide .link-arrow {
    width: 8.3px;
    height: 8.3px;
}

@media screen and (max-width:575px) {
    .assets_page {
        padding: 0 16px;
    }

    .head-banner {
        margin: 64px 0;
    }

    .guidelines {
        row-gap: 40px;
        margin-bottom: 64px;
    }
}