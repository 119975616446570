.orange-text {
    color: #EF3E23;
}

.yellow-text {
    color: #B28F02;
}

.white-text {
    color: #FFFFFF;
}

.black-text {
    color: #141744;
}

.gray-text {
    color: #808080;
}

.primary-text {
    color: #101828;
}

.secondary-text {
    color: #344054;
}

.teritiary-text {
    color: #808080;
}

.Meuso_300 {
    font-family: 'MuseoSans_300' !important;
}

.new-container {
    padding: 48px 12%;
}

.header-text-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 768px;
    max-width: 100%;
}

.heading {
    font-family: 'MuseoSans_300' !important;
    font-size: 48px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: -0.02em;
    text-align: left;
}

.sub-heading {
    font-size: 48px;
    font-weight: 600;
    line-height: 48px;
    text-align: left;
}

.sub-head {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
}

.support-text {
    font-family: "MuseoSans_500";
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
}

.support-text p {
    margin-bottom: 0;
}

/* Fonts */

.text-xl-semibold {
    /* styleName: Text xl/Semibold; */
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
}

.text-lg-semibold {
    /* styleName: Text lg/Semibold; */
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;

}

.text-md-semibold {
    /* styleName: Text md/Semibold; */
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
}

.text-sm-semibold {
    /* styleName: Text sm/Semibold; */
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
}

.text-xl-regular {
    /* styleName: Text xl/Regular; */
    font-family: DM Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
}

.text-md-regular {
    /* styleName: Text md/Regular; */
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

}

.text-sm-regular {
    /* styleName: Text sm/Regular; */
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.text-md-medium {
    /* styleName: Text md/Medium; */
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}

.text-sm-medium {
    /* styleName: Text sm/Medium; */
    font-family: DM Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}

.text-xs-medium {
    /* styleName: Text xs/Medium; */
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
}

.display-xl-semibold {
    /* styleName: Display xl/Semibold; */
    font-size: 60px;
    font-weight: 600;
    line-height: 72px;
    letter-spacing: -0.02em;
}

.display-lg-regular {
    /* styleName: Display lg/Regular; */
    font-family: 'MuseoSans_300' !important;
    font-size: 48px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: -0.02em;
    text-align: left;
}

.display-lg-medium {
    /* styleName: Display lg/Medium; */
    font-family: 'MuseoSans_300' !important;
    font-size: 48px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: -0.02em;
    text-align: left;
}

.display-md-medium {
    /* styleName: Display lg/Medium; */
    font-family: Inter;
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: -0.02em;
    text-align: left;
}

.display-sm-medium {
    /* styleName: Display sm/Semibold; */
    font-family: Inter;
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    text-align: left;
}

.display-xs-medium {
    /* styleName: Display xs/Semibold; */
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;

}

.display-lg-semibold {
    /* styleName: Display lg/Semibold; */
    font-family: 'MuseoSans_300' !important;
    font-size: 48px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: -0.02em;
    text-align: left;
}

.display-md-semibold {
    /* styleName: Display md/Semibold; */
    font-family: Inter;
    font-size: 36px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -0.02em;
    text-align: left;
}

.display-sm-semibold {
    /* styleName: Display sm/Semibold; */
    font-family: Inter;
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    text-align: left;
    color: #101828;
}

.display-xs-semibold {
    /* styleName: Display xs/Semibold; */
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;

}

.main-description h2,
.main-description h3,
.main-description h4,
.main-description h5,
.main-description h6 {
    font-family: Museo Sans;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #141744;
}

.main-description p {
    color: #808080;
    font-family: Museo Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.main-description blockquote {
    border-left: 2px solid #7F56D9;
    padding-left: 20px;
    /* styleName: Display xs/Medium italic; */
    font-family: Inter;
    font-size: 24px;
    font-style: italic;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    color: #101828;
}

.main-description blockquote p,
.main-description blockquote span {
    /* styleName: Display xs/Medium italic; */
    font-family: Inter;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #101828;
}

.main-description {
    width: 60%;
}

.main-description img {
    margin: 48px 0;
    height: 100%;
    max-width: 100%;
}

.link-text {
    display: flex;
    justify-content: space-between;
    column-gap: 16px;
}

.r-180deg {
    transform: rotate(180deg);
}

.lineclamp-two {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    max-height: 48px;
    min-height: 48px;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (max-width:575px) {
    .heading {
        font-size: 36px;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.02em;
    }

    .support-text {
        font-family: Plus Jakarta Sans;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
    }

    .main-description {
        width: 100%;
    }

    .main-description h2,
    .main-description h3,
    .main-description h4,
    .main-description h5,
    .main-description h6 {
        font-family: Museo Sans;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        text-align: left;
        color: #101828;
    }

    .main-description p {
        font-size: 16px;
        line-height: 24px;
    }

    .main-description blockquote {
        padding-left: 10px;
    }

    .main-description img {
        margin: 40px 0;
    }

    .new-container {
        padding: 48px 16px;
    }
}