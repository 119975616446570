body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "MuseoSans_500",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html *
{
   
   font-family: MuseoSans_500 !important;

}

.magic-dots.slick-dots li button:before{
  font-size: 8px!important
}

.sitemap{
  display: flex;
  flex-direction: column;
  padding: 10px
}
.sitemapxml{
  border-bottom: 1px solid black
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'MuseoSans_500';
  src: local('MuseoSans_500'), url(./fonts/Fonts/MuseoSans_500.otf) format('opentype');
  /* font-family: 'Museo_300';
  src: local('Museo_300'), url(./fonts/Fonts/Museo_300.otf) format('opentype'); */
}
@font-face {
 
  font-family: 'MuseoSans_700';
  src: local('MuseoSans_700'), url(./fonts/Fonts/MuseoSans_700.otf) format('opentype');
}

@font-face {
 
  font-family: 'MuseoSans_300';
  src: local('MuseoSans_300'), url(./fonts/Fonts/MuseoSans_300.otf) format('opentype');
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  -moz-appearance:textfield;
  margin: 0; 
}