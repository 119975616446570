.about_header2 .pr_menu{
    text-align: left;
    padding: 0 15px;
}
.header button:focus{
    outline: none!important
}

.header button span:focus{
    outline: none!important
}
.about_header2,.about_header{
    cursor: pointer;
}
.plus_min{
    font-size: 20px;
    margin-left: -40px;
    margin-top: -5px
}
.color_active{
    color: red!important
}

@media (min-width:500px){
    .about_header2 div:nth-child(2){
        display: none
    }
    .navbar_mobile .about_header2 div{
        padding: 0;
        
    }
    .cl_menu{
        display: none
    }
    
}
@media (max-width:500px){
    .header .navbar_mobile div:hover{
        color: #141744
    }
    .header .navbar-collapse{
        position: absolute;
        width: 100%;
        z-index: 5;
        top: 90%;
        right: 0;
        background-color: white;
        -webkit-box-shadow: 0px 13px 19px 0px rgba(199,193,199,0.5);
        -moz-box-shadow: 0px 13px 19px 0px rgba(199,193,199,0.5);
        box-shadow: 0px 13px 19px 0px rgba(199,193,199,0.5);    }
    .pr_menu{
        display: none
    }
    .about_header2{
        display: flex;
        flex-wrap: wrap
    }
    .cl_menu{
        width: 100%
    }
    
    .about_header2 div:nth-child(1){
        width: 100%
    }
    
    .about_header2 div:nth-child(2){
        padding: 15px 0;
        margin-left: -40px;
    }
    .navbar_mobile .about_header2{
        padding: 0;
    }
    .navbar-nav .about_header2 a{
        border-bottom: none
    }
    .navbar-nav a{
        border-bottom: 0.5px solid #c9c8c8b8
    }
    .navbar-nav .about_header2{
        border-bottom:0.5px solid #c9c8c8b8
    }
    
}
