.graph_text {
    color: #141744
}

.clients {
    text-align: center
}

.bggrey {
    background-color: #f0f0f0 !important
}

.truncate {
    overflow: hidden;
    height: 190px;
}

.foundation a:hover {
    color: #EF3E23
}

.foundation tbody tr td p {
    text-align: center !important;
    color: grey !important
}

.foundation thead tr th p {
    text-align: center !important;
    color: grey !important
}

.foundation table p font {
    color: grey !important
}

#how-rerl-works img {
    width: 100%;
    object-fit: contain;
}

.trow p:after {
    content: "\a" !important;
    white-space: pre;
}

.trow p {
    display: inline
}

.truncate_contact {
    overflow: hidden;
}

.about_page .note {
    padding: 1% 5%
}

.note {
    font-family: 'MuseoSans_300' !important;
    padding: 1% 0;
    color: grey;
}

.truncate_expand {
    height: 60px;
    overflow: hidden;
}

.about_page .about_management .popup {
    position: fixed
}

.truncate_expand>* {
    font-size: 1rem;
    font-weight: 100;
    margin-bottom: 0.5rem
}

.about_page {
    color: gray
}


.text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    font-size: 16px;
    line-height: 20px;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    width: 200px;
    border: solid 1px black;
    padding: 8px;

}

form input[type="number"] {
    -moz-appearance: textfield;
}

.benefit_list li {
    list-style: none;
}

.benefit_list li:before {
    content: "";
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAa9JREFUOBGtlDFPwkAUx9tSRhJIRwcX/QBOToKpzDqyG5rwAdj5Av0ATUrc2YyzmoCTn0EXB+aSOJJQ///nu+ZOAgGkyfXu3fu/X9/13p3nHfnxN/GyLLuBr1eWZRv9iermvu/PMJ4MBoMXnXO6NSBA54DkUHUc5boxBTwB+MN2BbYBWBuwd8x1IP5GG9VqtYsoihpsHHOOPmqozfP8ymZUGWpmhLXQXuv1+n2/3/+yxWY8Ho9Pl8vlA+wYrcAHLk2moRHpMgUGZxei0vj+9vwQ9F0k8QxfrLHX1MmS4eAGyDKZ2TYYg/hQo1pZvjJ+gfD3ROV56aZlqt/pVJvqpDAkQ6TM0vCCIHhyInYwTEzF0Bips2az+bkDw5FYMcJwysZRHmgY4Jzxi8XibF+OFSMMAWLHZgStVqvbfYEmxjBMhhMFDVm0u0JVO1S9MASoB32KnWrwBKCvTtAmODWqbUAzVUZVhyzUBI4CLeYJ2JYpfeaUMEZjMUTBy1tfEPFyeITZgognIGWdmdLgBug/G3I18BdhGN4lSfKmCBfISUD/dX05GZqvKPigC9ZmHGX8A4NexSAAYX4rAAAAAElFTkSuQmCC);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto;
    background-size: 11px 11px;
    display: inline-block;
    height: 15px;
    width: 15px;
    background-position: 40% 76%;
    background-repeat: no-repeat;
}

.intro_top br:after {
    content: url('../assets/Note.png');
    position: relative;
    top: 5px;
    margin-right: 5px;
}


.rerl {
    padding: 0 5%
}

.rerl_port {
    margin-bottom: 0 !important;
    padding: 0 5%;
    background-color: #f0f0f0
}

.about_padding {
    padding: 4.5% 5% 0 5%
}

.intro {
    position: relative;
    padding: 5%;
    bottom: 157px;
    background-color: white;
    margin: 0 4%;
    margin-bottom: -150px;
}


.intro ul {
    margin-left: -8%;
}

.about_page .products {
    padding: 0 5%;
    padding-bottom: 0;
    margin: 5% 0;
    background-color: white;
    border: none
}


.about_page .card-title {
    margin-bottom: 0
}

.about_page .benefit_list_item {
    padding: 1% 0
}

.rerl img {
    max-width: 100%;
    padding: 3% 5%
}

.rerl_port img {
    max-width: 100%;
}

.column {
    display: flex;

}

.column div {
    width: 50%;
    padding: 0 5% 7% 0;

}

.t_head {
    font-weight: bold;
}

.grey_table {
    background-color: #f0f0f0
}

.about_table {
    padding: 5%;
}

.about_table_div {
    color: black;
    margin: 2% 0
}

.about_table_div .card-title {
    padding: 0 5% !important;
}

.about_card {
    border-radius: 3px;
    margin: 5% 0;
}

.about_card_text {
    padding: 5%
}

.foundation {
    padding: 3% 11.5% 2.5% 11.5%;
    /* margin-top: 5%; */
}

.about_management {
    padding: 3.5% 5%;
    background-color: #f0f0f0
}

.about_card_div .about_card {
    background-color: white;
    -webkit-box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.18);
    box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.18);
}

.graph_leg {
    display: flex
}

.graph_leg_color {
    height: 30px;
    width: 30px;
    background-color: black;
    margin-right: 10px;
}

.about_p p {
    margin-bottom: 0.5rem;
}

.about_readmore {
    padding: 0 5%
}

.clients-title {
    margin-top: 5%;
}

/* responsive */
@media (max-width: 500px) {
    .clients {
        padding: 7% 0;
        margin-bottom: 8%
    }

    /* .about_management .clients .slick-dots{
        bottom: -60px
    } */
    .truncate_expand {
        height: 50px;
    }

    .benefit_list p:before {
        margin-right: 5px
    }

    .about_pad {
        padding: 2px 0
    }

    .about_management {
        margin-top: 7%
    }

    .about_card_div {
        margin-top: 8%
    }
}

@media (max-width: 768px) {
    .about_management .card-title {
        padding: 3% 0
    }

    /* .about_management .truncate_contact{
    height: 108px;
} */
    .graph_leg {
        padding: 5px 0
    }

    .about_table_div {
        margin: 0
    }

    .popup h2 {
        font-size: 1.5rem
    }

    .intro_top .image {
        display: none
    }

    .intro {
        bottom: 145px;
    }

    .about_page {
        overflow-x: hidden
    }
}

@media (min-width: 768px) {
    .about_card_text {
        padding: 10px 5%
    }


    .fund_title .what_we {
        padding: 1% 6% 0% 6%;
    }

    .what_we {
        padding: 6% 6% 6% 6%;
    }

    .what_we_headsub {
        padding-bottom: 0
    }

    .about_page .about_management .popup {
        top: 85px
    }

    .about_page .intro .intro_top .image {
        width: 25%
    }

    .about_page .products .features {
        padding: 0 2.15%;
    }

    .about_table .trow {
        margin: 10% 0 0 0
    }

    .intro .bullets {
        margin: 0
    }

    .about_p p {
        text-indent: -1.5em;
    }


    .intro_top {
        display: flex;
        justify-content: space-around;
    }

    .intro_top img {
        height: 180px
    }

    .about_p {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between
    }

    .about_p p {
        width: 48%;
        margin-bottom: 5px
    }

    .column div {
        width: auto;
        padding: 0 6% 4% 0;

    }

    .about_card_div {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 10px
    }

    .about_card {
        margin: 1% 0
    }

    .about_card_div .about_card {
        width: 49%
    }

    .about_page .card-title {
        margin-bottom: 0.8rem;
        font-size: 30px
    }

    .profile .about_page .card-title {
        font-size: 20px
    }

    .about_page .contact_title {
        padding: 3% 8%;
        height: 300px;
    }

    .intro ul {
        margin-left: -3%;
    }

    .bullets p {
        margin-bottom: 0
    }

    .about_page .investors {
        background-color: #f0f0f0
    }

    .about_page .benefit_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between
    }

    .about_page .benefit_list_item {
        width: 48%
    }

    .about_page .products {
        padding: 1% 9%
    }

    .clients {
        display: block;
        padding: 0 20%;
        text-align: center;
        margin: 5% 0
    }

    .clients .card-title {
        margin: 5% 0
    }

    .intro {
        width: 84%;
        margin: -3% auto;
        padding: 4%;

    }

    /* .about_page .products_head{
    padding-top: 4%
} */

    .rerl {
        padding: 0 4%;
        margin-top: -12%;
    }

    .rerl_head {
        padding: 3% 9% 0% 9% !important;
    }

    .rerl_port {
        display: flex;
        padding: 0% 9% 0% 9%;
        align-items: center;
        justify-content: space-between;
    }

    .rerl_port_img {
        width: 48%
    }

    .rerl_port_leg {
        width: 48%
    }

    .graph_leg {
        padding: 2% 4%
    }


    .about_table_div .card-title {
        padding: 1.5% 9% !important;
    }

    .about_table {
        display: flex;
        padding: 0% 1%;
        margin: 0 9%;
    }

    .column {
        flex-direction: column
    }

    .column {
        width: 25%;
    }

    .t_head {
        min-height: 70px;
        background-color: white;
        margin: 0 -5%;
        padding-left: 5% !important;
        padding-right: 5% !important;

    }

    .about_management {
        padding: 3% 9% 2.5% 9%;
        margin-top: 5%
    }

    .slick-dots,
    .slick-next,
    .slick-prev {
        position: static !important
    }

}