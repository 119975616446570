.accordion__arrow{
    position: relative!important;
    margin-top: -1%!important;
    margin-right: 1.82%!important
}

.faq .contact_form{
    padding: 5%;
    margin-bottom: -168px;
    min-height: 125px
}

.accordion__title{
    background-color: white!important;
    padding: 10px 0!important;
    outline:none
}

.accordion__title .sub{
    display:flex;
    justify-content: space-between;
    align-items: center
}

.accordion{
    border: none!important;
    padding: 1% 2%;
}
.accordion__item .sub .card-title{
    width: 90%;
    font-size: 17px;
}

.accordion__item + .accordion__item {
    border-top: none!important;
}

.accordion__item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.accordion__body {
    padding: 0 5% 5% 10%!important;
}

.accordion__body p{
    color: #707070!important ;
}

.faq button {
    background-color: white;
    border-color: #EF3E23;
    color: #EF3E23;
    width: 60%;
    font-weight: 600;
    border-radius: 0.4rem;
}

.faq .contact_form .accordion__item .card-title{
    margin: 0.5rem
}

.faq .contact_title{
    padding: 30px 7.75%;

}

.faq_button2{
    display: flex;
    border-color: #EF3E23;
    color: #EF3E23;
    width: 65%;
    background-color: #f0f0f0;
    font-weight: 600;
    border-radius: 0.4rem;
    border: 1px solid;
    justify-content: space-around;
    height: 32px;
}


.faq_button2 div{
    width: 50%;
    text-align: center;
    padding: 0.24rem .75rem;
    font-size: 1rem


}

.faq_button2_active{
    background-color: #EF3E23;
    color: white;
    border-radius: 0.4rem;

}

.faq_button2_inactive{

    color: #EF3E23;
    text-align: center;
    border:none;
    cursor: pointer
}

.faq_header{
    display: flex;
    justify-content: space-between;
    font-family: 'MuseoSans_700' !important;
    font-weight: 700
}


/* //responsive */
@media (max-width: 768px)
{
    .faq .contact_title {
        padding: 12px 7.75%;
    }
.accordion__item .sub .card-title{
    font-size: 15px
}
.accordion__body p{
    font-size: 13px

}
.accordion__arrow{
    width: 25px!important;
}
.faq .contact_form{
    width: 85%;
    padding: 2%
}
.accordion .accordion__title {
    padding: 10px 6px!important;
}
.accordion__item .accordion__body{
    padding: 0 5% 5% 7%!important;
}
}

@media (min-width: 768px)
{   
    .faq_button2{
        height: 40px;
        line-height: 30px;
    }
    .faq .contact_form {
        margin-bottom: -190px;
    }
.faq_header {
    align-items: center;
    font-size: 32px;
}


.faq .contact_form {
    width: 84.5%;
    padding: 1%;

}

.accordion__body {
    padding: 0 0% 2% 3.25%!important;
}

.faq_button2 {
    
    width: 25%;

}

.accordion__arrow {
    
    right: -2%!important;

}

}