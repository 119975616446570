.getfundpage .what_we_div ul {
    padding: 10px 3.5%;

}

.capsend {
    align-items: center;
    flex-wrap: wrap;
    display: flex
}


.getfundpage .buttons .uploaded_span {
    color: red
}

.getfundpage .buttons .uploaded_span:hover {
    color: white;
    background-color: grey
}

.note {
    font-family: 'MuseoSans_300' !important;
    padding: 1% 0;
    color: grey;
}

.getfundpage .what_we_div ul li {
    font-family: 'MuseoSans_300' !important;

}

.buttons .btn {
    border-color: #a5a5a5;
    color: #828080;
    font-weight: 400;
}

.fund_title_text {
    font-family: 'MuseoSans_700' !important;
    font-weight: 700
}

.buttons .btnsend {
    border-color: #EF3E23;
    color: #EF3E23;
    font-weight: 400;
}

input:focus {
    outline: none
}

.btn-file {
    position: relative;
    overflow: hidden;
    border-color: #a5a5a5;
    color: #828080;
    font-weight: 400;
    background-color: white
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    cursor: inherit;
    display: block;

}

/* input[type="file"] {
    position: relative;
    left: -21%;
    bottom: 1%;
} */
.custom-file-upload {
    border-color: #a5a5a5;
    color: #828080;
    font-weight: 400;
    display: inline-block;
    border: 1px solid #a5a5a5;
    border-radius: 0.4rem;
    padding: 6px 12px;
    cursor: pointer;
    position: relative;
    z-index: 2;
    background-color: white;
    min-width: 135px;
}

.custom-file-upload:hover {
    background-color: grey !important;
    border-color: grey;
    color: white
}

.popup_business button {
    width: 100%;
    background-color: #EF3E23;
    border-color: #EF3E23
}

.popup_business button:hover {

    /* box-shadow:  0px 0px 1px 1px #EF3E23!important; */
    border-color: #EF3E23;
    background-color: #EF3E23;

}

.popup_close_bus {
    bottom: 15px;
    left: 25px;

}

.buttons .btn:hover {
    background-color: grey;
    border-color: grey;
    color: white !important
}

.buttons .btnsend:hover {
    /* box-shadow:  0px 0px 2px 1px #EF3E23!important; */
    border-color: #EF3E23;
    background-color: #EF3E23;
    color: white
}

.popup_business button:hover {
    box-shadow: 0px 0px 1px 1px #EF3E23 !important;
}

button.btnshadow:hover {
    box-shadow: 0px 0px 2px 1px #EF3E23 !important;

}

.popup_business .buttons .btnsend {
    color: white;
}

.popup_business .buttons {
    display: flex;
    justify-content: space-between;
}


.fund_title {
    background-color: #f0f0f0;
    margin-top: 55%;
}

.fund_title_card {
    padding: 0 6%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 88%;
    margin: 0 auto;
    position: relative;
    bottom: 195px;
    margin-bottom: -195px;
    padding-bottom: 2.5%;
    color: #141744;
    font-size: 22px;
    font-weight: 600;
}

.fund_title_card img {
    height: 220px;
    margin-bottom: 5%;
}

.what_we {
    padding: 6% 6% 0% 6%;
    margin-bottom: 0%;
}

.what_we img {
    height: 45px
}

.what_we_head {
    color: #141744;
    font-size: 24px;
    font-weight: 600;
    padding-top: 5%;
    padding-bottom: 25px;
    font-family: 'MuseoSans_700' !important;
}

.cc_code {
    text-transform: none;
    position: absolute;
    height: calc(100% - 2px);
    width: 45px;
    font-size: 14px;
    border-radius: 0px;
    background: #fff;
    border: 0px solid;
    margin: 1px 3px;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fund_form_head {
    color: #141744;
    font-size: 22px;
    font-weight: 600;
    padding: 8% 6% 8% 6%;
}

.what_we_headsub {
    padding-bottom: 5%;
}

.what_we_subhead {
    color: #141744;
    font-size: 20px;
    font-weight: 600;
    font-family: 'MuseoSans_700' !important;
}


.fund_form {
    width: 88%;
    margin: 0 auto;
    color: #495057;
}

.fund_form .form-control {
    border: 1px solid #c1bfbf;
    border-radius: 0.35rem;

}


.fund_form textarea {
    height: 120px !important
}



.fund_form button {
    background-color: white;
    border-color: #EF3E23;
    color: #EF3E23;
    width: 45%;
    font-weight: 600;
    border-radius: 0.4rem;
}

.fund_form button:hover {
    background-color: #EF3E23;
    border-color: #EF3E23;
    color: white;
}

.fund_form .button {
    display: flex;
    justify-content: space-between;
}

.fund_grey {
    background-color: #f0f0f0;
    margin-top: 28%;
    padding: 5% 6%;
}


.fund_grey .card_container {

    bottom: 89px;
    margin-bottom: -30%;
    padding: 0%;
}

.fund_testimonials {
    background-color: #f0f0f0;
    padding: 8% 0;
    margin: 25% 0;

}

.fund_testimonials .testimonials {
    bottom: 0;
    margin-bottom: -7%;
}

.fund_testimonials_card_bottom {

    top: 40px;
    margin-bottom: -18%;
}

.fund_newsletter .card button {
    width: 65%;
    padding: 3px 0.5rem;
}

.fund_newsletter form {
    display: flex;
    flex-direction: column;
}

.fund_newsletter input {
    text-align: center;
    border: none;
    border-bottom: 1px solid;
    width: 70%;
    margin: 0 auto;
}

.fund_newsletter button {
    border-color: #EF3E23;
    color: #EF3E23;
    border-radius: 3px;
    box-shadow: none;
    border: 1px;
    margin-top: 4%;
    content: 'hi'
}

.fund_form .buttons {
    display: flex;
    justify-content: space-between;
}

.fund_newsletter button:after {

    content: 'Sign Up for newsletters';
    color: white;
    margin-left: -25%;
}

/* responsive */

@media (max-width: 768px) {
    .note {
        padding: 2% 0;
    }

    .getfundpage {
        overflow-x: hidden
    }

    .getfundpage .what_we_div ul {
        padding: 0 5.75%;
        margin-bottom: 0;

    }

    .capsend .fund_form_md_sub {
        max-width: 100%;
    }

    .fund_newsletter .card button {
        font-size: 13px;

        padding: 7px 0.5rem;

    }

    .fund_newsletter button:after {

        margin-left: -33%;
    }

    .popup_business .buttons .btnsend {
        margin: 10px 0;
    }
}

@media (max-width: 500px) {
    .mailchimpnew .clear input {
        width: 85%
    }

    .popup_business .buttons {
        flex-direction: column;
        /* align-items: flex-start; */
    }

    .fund_deals_executed {
        padding: 9% 0
    }

    .fund_testimonials_card_bottom .card .card-title {
        padding: 0 10%
    }

    .fund_testimonials .profile .card-title {
        margin-top: 0
    }

    .footer_logo a {
        padding-top: 3.5%;

    }
}

@media (min-width: 768px) {
    .getfundtopimg {
        width: 66%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
    }

    .fund_form_head {
        font-size: 20px;
    }

    .fund_form textarea {
        height: 200px !important
    }

    .fund_form button {
        max-width: 140px;
    }

    .what_we_subhead {
        font-size: 26px;
        font-weight: 500
    }

    .popup_business {
        width: 80%;
        top: 10%;
        left: 9.5%
    }

    .popup_business .card_contact textarea {
        height: 200px !important;
    }

    .popup_business .card_contact {

        padding: 3% 5% !important;
    }

    .popup_business .card-title {
        text-align: left;
        position: relative;
        bottom: 15px;
        font-size: 23px;
    }

    .fund_deals_executed .deals_executed {
        margin: 5% 0% 0% 0;
    }

    .fund_grey .what_we_head {
        font-size: 26px;
        margin-top: 2%;
        margin-bottom: 1%;

    }

    .fund_title {
        margin-top: 17%;
        padding: 0 2.5%;
    }

    .fund_title_card {
        flex-direction: row;
        justify-content: center;
    }

    .fund_title_card img {
        height: 300px;
    }

    .fund_title_text {
        padding-left: 10%;
        font-size: 35px;
        line-height: 45px;
    }

    .what_we_head {
        font-size: 34px;
        text-align: center;
        font-weight: 500
    }


    .what_we_div {
        display: flex;
        justify-content: space-between
    }

    .what_we_headsub {
        width: 49%
    }

    .what_we_headsub p {
        margin-top: 10px;
        padding: 0 5% 0 0%;
        font-family: 'MuseoSans_300' !important
    }


    .fund_div {
        padding: 0 8.5%;
    }

    .fund_form_head {
        padding: 5% 0%;
    }

    .fund_form {
        margin: 0;
        width: 100%
    }

    .fund_form_md {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap
    }

    .fund_form_md_sub {
        width: 48.5%
    }

    .fund_div .buttons {
        display: flex;
        justify-content: space-between
    }

    .fund_grey {
        margin-top: 14%;
        padding: 7% 8.5% 5% 8.5%;
    }

    .fund_grey .what_we_head {

        text-align: left;
    }

    .fund_grey .card_container {
        bottom: 170px;
        margin-bottom: -16%;
        padding: 0%;
        width: 83%;
        margin-left: auto;
        margin-right: 9%;
    }

    .fund_grey .card_container .card {
        min-height: 0;
        padding: 5% 5% !important;
    }

    .fund_testimonials {

        margin: 9% 0;
    }

    .fund_testimonials .card-title {
        margin-top: 0.75em;
    }

    .fund_testimonials .testimonials {
        width: 80%;
    }

    .fund_testimonials .card_container {
        bottom: 190px;
        margin-bottom: -11%;
        padding: 0%;
        width: 65%;
        margin-left: auto;
        margin-right: auto;
    }

    .fund_grey .card_container .card {
        min-height: 0;
        padding: 5% 5% !important;
        flex-direction: row;
        justify-content: space-between
    }

    .fund_testimonials .card_container .card {
        min-height: 0;
        padding: 5% 5% !important;
        flex-direction: row;
        justify-content: space-between
    }

    .fund_testimonials .card_container .card button {
        width: 25%
    }

    .fund_testimonials_card_bottom .card_container {
        bottom: 170px;
        margin-bottom: -21%;
        padding: 0%;
        width: 61%;
        margin-left: auto;
        margin-right: auto;
    }

    .fund_testimonials_card_bottom .card_container .card {
        min-height: 0;
        padding: 5% 5% !important;
    }

    .fund_testimonials_card_bottom {
        top: 150px;
        margin-bottom: -2% !important;
    }


    .fund_newsletter .card button {
        width: 50%;
        padding: 5px 0.5rem;
    }

    .fund_newsletter .card-body {
        padding: 5% !important;
    }

    .fund_newsletter .card-body {
        padding: 0 20%;
    }

}