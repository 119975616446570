.joinus_form .fund_form{
    width: 100%
}
.contactpage .contact_flex,.contactpage .contact_address{
    padding-right: 0;
    padding-left: 0
}
.siteloading img{
    margin: 0 auto;
    
}

.loader {
    position: fixed;
    top: 25%;
    right: 50%;
    z-index: 3;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #EF3E23;
    width: 90px;
    height: 90px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  .joinus_form .fund_div{
      padding: 0
  }
  .joinus_form .fund_form_head{
    padding: 0 0 1% 0;
  }
  .joinus_form_head{
    padding: 2% 0;
    /* font-size: 18px; */
    font-family: MuseoSans_300 !important;
  }
.header .navbar_mobile .about_header2 .pr_menu,.header .navbar_mobile .about_header .pr_menu{
    text-align: left;
    padding: 0 15px;
}
.contact_flex{
    display: flex;
    flex-wrap: wrap
}
.contact_flex button{
    margin: 0 15px
}
.contact_address a:hover{
    color: #EF3E23
}

.address_body p{
    margin-bottom: 0
}
.contact_address .address_body{
word-break: break-all
}
.formvalidation
{
    text-align: center;
    background-color: rgb(239, 62, 35);
    color: white;
    min-height: 35px;
    margin: 0 auto;
    line-height: 35px;
    z-index: 100;
    position: fixed;
    width: 100%;
    top:0
}


  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

.popup{
    display: flex;
    position: fixed;
    align-items: center;
    z-index: 3;
    flex-direction: column;
    top: 7%;
    width: 100%
}

.popup .card_contact {
    padding: 5%!important;
    width: 80%;
    background-color: white;
    margin:0;
    overflow-y: auto;

}

.popup_footer
    {
        width: 80%;
        height: 25px;
        background-color: #EF3E23;
    }


.cursor{
    cursor: pointer;
}

.overlay{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: black;
    z-index: 2;
    opacity: 0.6;
}

.contact_form a{
    /* color: #495057!important */
}

.popup_foottext_div{
    display: flex
}

.popup_foottext{
    font-size: 19px;
}
.popup_foottext_div div{
    margin-right:5px
}

.popup_foottext .blue{
    color: #141744
}

.popup_foottext .grey{
    color: grey
}

.popup_foottext .orange{
    color: #EF3E23
}

.popup_close{
    position: relative;
    bottom: 30px;
    left: 10px;
    text-align: right;
    font-size: larger;
    margin-bottom: -5%;
}

.popup .card_contact_desgn {
    font-size: 24px;
    color: #141744;
    font-weight: 600;
}

.popup .card_contact_loc_date div {
    margin-right: 8%
}

.contact_title{
    background-color: #f0f0f0;
    padding: 4.35% 3.35% 3.35% 3.35%;
    font-size: 25px;
    height: 230px;
}


.career_date{
    margin-left: 5px
}

.contact_form{
    padding: 10% 5%;
    width: 95%;
    margin: 0 auto;
    background-color: white;
    position: relative;
    bottom: 150px;
    margin-bottom: -190px;
    color: #495057;
    font-family: MuseoSans_300 !important;
}

.contact_form .form-control{
    border: 1px solid #151c44;
    border-radius: 0.12rem;
    
}
textarea.form-control {
    height: auto;
    border-radius: 0.4rem!important;
}

.contact_form textarea{
    height: 200px!important
}
select:focus{
    outline: none!important
}

.contactpage .cc_code,.joinus_form .cc_code,.joinus_form_pop .cc_code .investpage .cc_code{
    text-transform: none;
    position: absolute;
    height: calc(100% - 4px);
    width: auto;
    font-size: 11.5px;
    border-radius: 0px;
    background: #fff;
    border: 0px solid;
    margin: 1px 3px;
    max-width: 56px
}

.contact_form button{
    background-color: white;
    border-color: #EF3E23;
    color: #EF3E23;
    width: 60%;
    font-weight: 600;
    border-radius: 0.4rem;
}

.contact_form button:hover{
    background-color: #EF3E23;
    border-color: #EF3E23;
    color: white;
}
.contact_address_div{
    padding: 5% 0
}

.accordion__body p,.accordion__body span,.popup .card_contact_desc p span,.card_contact_loc_date div,.intro .bullets p,.about_management .truncate_contact p,.product_page p,.address_body p{
    font-family: MuseoSans_300 !important;

}
.about_management .truncate_contact p{
    margin-top: 10px
}
.popup .card_contact_desc

.contact_address p{
    margin-bottom: 0!important;
    font-family: MuseoSans_300 !important;
}
.truncate_contact p span{
    font-family: MuseoSans_300 !important;
}

.card_contact_loc_date div{
    font-family: MuseoSans_300 !important;
    margin-right:10%
}

.contact_address br {
    /* display: none; */
}

.contact_address_head{
    padding-top: 3%;
    color: #343a40;
    font-weight: 600;
}

.contact_address_sub{
    color:#495057
}

.contact_career .card-title{
    margin-bottom: 0;
    font-size: 30px;
}

.contact_career{
    padding:2% 0 5px 0;
}

.card_contact{
    border: 1px solid #d8d5d5;
    padding: 6% 8%;
    border-radius: 3px;
    margin: 5% 0;
    
}

.card_contact_desgn{
    font-size: 22px;
    color: #141744;
}

.card_contact_loc_date
{
    display: flex;
    padding: 10px 0;
}


/* responsive desktop */
@media (max-width: 767px)

    {
        .contact_address .address_body{
        border-bottom: 1px solid #d8d8d8;
        margin-bottom: 20px
    }  
    .contact_address .address_body:last-child{
        border-bottom: none;
        margin-bottom: 0
    }
 }

@media (max-width: 767px)

    {   
        
    .joinus_form .fund_form{
        width: 100%
        }

    .joinus_form .fund_form_head{
        font-size: 17px;
        padding: 10px 0;
    }
    .joinus_form_head {
        font-size: 16px;}

     .popup .card_contact {
        max-height: 600px
}
    
    .contact_form{
    bottom: 165px

}   
.getfundpage .card_contact form{
    /* max-height: 300px */
}
    .getfundpage .card_contact form button{
        margin-bottom: 20px;
    }
.contact_career .card-title{
    font-size: 27px
}
    .popup_close {
        bottom: 10px;
        left: 5px;
    }
    .popup .card_contact{
        width: 80%
    }
    .popup_footer{
        width: 80%;
        position: relative;
        bottom: 5px;
    }

    .popup {
        
        bottom: 10%;
}

    .contactpage .popup{
        max-height: 80vh;
    }
.loader {
    
    left: 38%;
}


}
@media (min-width: 768px)
{
.contact_address{
    display: flex;
    margin: 5% 0 2% 0
}
.contact_address .address_body{
    padding: 0 2% 0 0;
    width: 25%
}
.contact_address{
    display: flex;
    flex-wrap: wrap;
}

.contact_career .card-title{
margin-bottom: 0;
font-size: 34px;
margin-top: 10px
}
    
.popup{
    
    top: 7%;
}




.contact{
    padding: 0 5.5%;
    height: 100%;
}

.contact_title {
    padding: 2% 7.75%;
    font-size: 30px;
    height: 290px
}

.contact_form {
    padding: 3%;
    bottom: 195px;
    margin-bottom: -210px;
}



.contact_address_head {
    padding-top: 0;
}

.contact_address_sub{
    width: 75%
}

.card_contact_container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 2% 0;
}

.card_contact {
    padding:3% 3% 1% 3%!important;
    width: 48.5%;
    margin: 2% 0;
    
}

.join_head{
    margin-bottom: -1.25rem!important;
    font-size: 26px
}

}