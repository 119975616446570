.podcast-page .carousel-one {
    margin-bottom: 128px;
}


.episode-details {
    display: flex;
    column-gap: 12px;
    align-items: center;
    margin-bottom: 12px;
}

.podcast-page .carousel-one .ep-no {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 104px;
    height: 59px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    border-bottom-right-radius: 10px;
}

.podcast-page .caorusel-img {
    aspect-ratio: 1.31;
    border-radius: 12px;
}

.episode-page .main-description-container.youtube {
    margin-top: 320px;
}

.episode-page .main-description-container {
    margin-bottom: 96px;
}

.pink-bg {
    background: linear-gradient(to bottom right, #FFD0C9 0%, #FFD0C9 50%, white 50%, white 70%);
    background-color: white;
    background-size: 450% 100%;
    height: 700px;
}

.play-minutes {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.play-minutes .play {
    display: flex;
    column-gap: 9px;
    align-items: center;
}

.play-minutes .minutes {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #212529;
}

.episode-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    height: 32px;
    border-radius: 9999px;
    padding: 4px 10px 4px 4px;
    column-gap: 12px;
    border: 1.5px solid #EF3E23;
    background-color: #FFD0C9;
    width: fit-content;
}

.episode-box .episode {
    border-radius: 9999px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    padding: 2px 10px;
    border: 1.5px solid #EF3E23;
}

.iframe-container .iframe {
    width: 916px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 64px;
}

.iframe-container.youtube .iframe {
    aspect-ratio: 1.77;
}

.iframe-container {
    display: flex;
    justify-content: center;
}