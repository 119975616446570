.interview-list {
    column-gap: 2.6%;
    row-gap: 48px;
    margin-bottom: 64px;
}

.interview-list .single-carousel {
    width: 31.6%;
}

.interview-list .card-one .main-image {
    object-fit: cover;
    aspect-ratio: 1.6;
    border-radius: 12px;
    margin-bottom: 20px;
}

.single-interview-page .other-details {
    margin-right: 0 !important;
}

.single-interview-page .main-image {
    margin-top: 64px;
}