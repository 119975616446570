.errorimg{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 90vh;
    text-align: center;
    font-size: 20px;
    padding: 0 2%;
}

.errorimg img{
    height: 130px;
}

.error{
    font-size: 80px;
    color: red;
    font-weight: 600;
}

.gotohome{
    color: red;

}

.error_grey{
    color:grey
}

.errorimg a:hover {
    text-decoration: none;
}

/* responsive */
@media (min-width: 767px)
{
.errorimg{
    flex-direction: row;
    text-align: left;
}

.errorimg img{
    margin-right:50px;
}

}