.testimonial-container {
    padding: 0 12%;
}

.orange-text {
    color: #EF3E23;
}

.white-text {
    color: #FFFFFF;
}

.testimonial-carousel {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2.6%;
    row-gap: 32px;
    padding: 48px 0;
}

.testimonial-carousel .card-2 {
    width: 31.6%;
    aspect-ratio: 0.8;
    position: relative;
    border-radius: 4px;
}

.testimonial-carousel .testimonial-details {
    column-gap: 48px;
}

.testimonial-carousel .card-2 .main-img {
    border-radius: 4px;
}

.card-2 .name {
    font-family: Inter;
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    text-align: left;
}

.card-2 .upright-arrow {
    width: 10px;
    height: 10px;
    cursor: pointer;
}

.text-lg-semibold {
    /* styleName: Text lg/Semibold; */
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;

}

.text-md-medium {
    /* styleName: Text md/Medium; */
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}

.testimonial-carousel .card-2 .detail-box {
    position: absolute;
    z-index: 2;
    color: white;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 24px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    border-radius: 0px, 0px, 4px, 4px;
}


.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: #0000004D;
}

.popup-details {
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    width: 90%;
    height: 90%;
    aspect-ratio: 1.47;
    z-index: 9;
    padding: 32px;
    border-radius: 12px;
    max-height: 890px;
}

.testimonial-details .light-img {
    height: 48px;
    object-fit: contain;
}

.testimonial-details .other-details .name {
    line-height: 28px;
}

.display-lg-medium {
    /* styleName: Display lg/Medium; */
    font-family: Inter;
    font-size: 48px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: -0.02em;
    text-align: left;
}

.testimonial-details.flex-row-reverse .other-details {
    padding-right: 0;
    padding-left: 48px;
}

.testimonial-details .other-details {
    width: 60%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
}

.popup-details .close_btn {
    display: flex;
    width: 24px;
    padding: 6px;
    object-fit: contain;
    align-items: flex-end;
    margin-left: auto;
    cursor: pointer;
}

.testimonial-details {
    height: 100%;
}

.testimonial-details .img-details {
    height: 100%;
    width: 40%;
    position: relative;
    display: flex;
}

.testimonial-carousel .card-2 .gradient-box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 134px;
    background: #FFFFFF4D;
    border-top: 1px solid #FFFFFF4D;
}

.testimonial-details .gradient-box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 220px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
}

.testimonial-details .img-details .main-img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    border-radius: 4px;
    object-fit: cover;
}

.testimonial-details .img-details .detail-box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    height: auto;
    color: white;
    padding: 32px;
    background: #FFFFFF4D;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-top: 1px solid #FFFFFF4D;
}

.testimonial-details .star-ratings {
    display: flex;
    column-gap: 4px;
}
.testimonial-details .quotes{
    font-weight: 300;
}

.testimonial-details .star-ratings .star {
    width: 20px;
    height: 20px;
    object-fit: contain;
}


@media screen and (max-width:575px) {
    .testimonial-container {
        padding: 0 16px;
    }

    .display-lg-medium {
        font-size: 24px;
        line-height: 30px;
    }

    .testimonial-carousel {
        padding-top: 16px;
        row-gap: 35px;
    }

    .testimonial-carousel .testimonial-details {
        flex-direction: column-reverse;
    }

    .testimonial-details .other-details .name {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
    }

    .testimonial-details .other-details {
        width: 100%;
    }

    .testimonial-details.flex-row-reverse .other-details {
        padding-left: 0px;
    }

    .text-lg-semibold {
        font-size: 16px;
        line-height: 24px;
    }

    .text-md-medium {
        font-size: 14px;
        line-height: 20px;
    }

    .testimonial-carousel .card-2 {
        width: 100%;
    }

    .popup-details .testimonial-details {
        flex-direction: column;
    }

    .popup-details .other-details {
        width: 100%;
        padding-right: 0;
        padding-bottom: 16px;
    }

    .testimonial-details .light-img {
        height: 32px;
    }

    .testimonial-details .img-details .detail-box {
        height: 68px;
        padding: 16px;
    }

    .testimonial-details .gradient-box {
        height: 180px;
    }

    .testimonial-details .img-details {
        width: 100%;
    }

    .testimonial-details .star-ratings .star {
        width: 16px;
        height: 16px;
    }
}