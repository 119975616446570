.invest_card .card{
    text-align: left;
    box-shadow: none
}
.invest_bottomcard #g-recaptcha{
    width: auto
}
.popup_invest .position-relative:last-child{
    display: flex;
    justify-content: center
}
#g-recaptcha{
    width: 100%;
    overflow: scroll;
    padding-bottom: 10px
}
.captcha{
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
}

.siteloading{
    width: 100%;
    display: flex;
    justify-content: center;
}
.invest_card button{
    align-self: unset;
    font-size: 14px;
    font-weight: 600;
    padding: .55rem 0.25rem;
}

.invest_button{
    background-color: #f0f0f0;
    padding: 0 3%;
    margin-top: 75%
}
.btn:hover{
    color:white!important
}

.invest_button_div{
    position: relative;
    bottom: 260px;
    margin-bottom: -270px;
}

.invest_business{
    background-color: white;
    /* height: 550px */
}

.invest_button img {
    height: 180px;
    
}
.invest_why1 p{
    font-family: 'MuseoSans_300'!important;
}

.invest_why p{
    font-family: 'MuseoSans_300'!important;
}

.invest_why1 {
    padding: 7% 0;
}

.invest_why{
    padding: 7% 3%;
}

.invest_why img {
    height: 110px;
}



.invest_why_grey{
    background-color: #f0f0f0
}

.invest_title{
    display: flex;
    align-items: flex-end;
    margin-bottom: 7%;
}

.invest_title .card-title{
    margin-bottom: 0;
    margin-left: 5%;
}

.invest_bottomcard{
    padding: 0 3%
}

.invest_bottomcard .form-control{
    border: none!important;
    text-align: center
}

.invest_bottomcard button{
    padding: 6px 0.25rem
}

.underline{
    border: 0.5px solid;
    width: 68%;
    align-self: center;
    margin-bottom: 4%;
    margin-top: -1%;
}

.form-control:focus{
    outline: none!important;
    box-shadow: none!important
}
.popup_business .card_contact{
    max-height: none!important
}

/* responsive */
@media (max-width:768px){
    .invest_button .invest_button_div .card{
        border: none!important;
        box-shadow:none!important
    }
    .invest_why1 .card-title{
        font-size: 21px
    }

}
@media (max-width:330px){
    .contact .contact_form {
        
        bottom: 160px;
    }
    .fund_title{
        margin-top: 60%;
    }
    .card_contact_desgn{
        font-size: 18px
    }
    .homtestimonial{
        margin-bottom:-120%
    }
    .invest_button{
        margin-top: 85%
    }
    .testimonials .card-text {
        font-size: 11px;
    }
    .milestone_text_number {
        font-size: 20px;
    }
    .homtestimonial {
        bottom: 400px;
    }
    .deals_carousel img{
        height: 50px;
    }    
    .popup{
        top: 12%!important;
    }
    .contactpage .popup {
        top: 90%!important;
    }
    .popup .card_contact {
        width: 85%!important;
    }
    .popup_footer {
        width: 85%!important;
    }
    .invest_card button{
        font-size: 13px;
    }
    .footer_links{
        font-size: 13px
    }

    .invest_button_div{
        bottom: 260px
    }

    .accordion__item .sub .card-title {
        width: 90%!important;
        font-size: 14px!important;
        margin-right: 3px;
    }

    .accordion__body > *:last-child {
        font-size: 12px;
    }

    .faq_button2 div{
        font-size: 14px!important
    }

    .faq_header{
        font-size: 23px
    }

    .fund_newsletter .card button {
        padding: 6px 0.5rem!important;
        font-size: 13px;
    }

    .card-title{
        font-size:18px
    }
    .invest_card .card button {
        font-size: 11px;
        padding: 5px
    }
    .invest_why img {
        height: 80px;
    }
    .fund_div .fund_form_head {
        font-size: 18px;
    }
    .footer_links a {
        font-size: 11px;
    }
    .product_page .contact_title {
        height: 200px;
    }
    .card button{
        font-size: 13px
    }
    .homepagediv .invest_business {
        margin-bottom: 77%;
    }
    .homepagediv .rec_fund {
        padding: 2% 0!important;
        margin-bottom: 7%;
    }

    .product_page p{
        font-size: 14px
    }

    .products_head{
        font-size: 20px
    }
    
    .features_head{
        font-size: 18px
    }

    .social_media img {
        height: 20px;
    }
}
@media (min-width: 768px)
{
    .invest_title{
        
        margin-bottom: 2%;
    }
    #g-recaptcha{
        overflow: hidden;
    }
    
.invest_desk .card-title{
    font-size: 28px
}
.popup_invest .card_contact {
    padding: 2% 5%!important;
}
.popup_invest{
    width: 70%!important;
    top: 5%!important;
    left: 14%!important;

}
.popup_invest button{
    margin-bottom: 10%;
}
.popup_invest .card-title{
margin-top: 5%;
}

.popup_invest form{
    width: 80%;
    margin: 0 auto;
}

.close_inv{
    bottom: -20px!important;
    left: 18px!important;
}
.invest_why_grey{
    flex-direction: row-reverse
    }

.invest_button {
   
    margin-top: 16%;
}

.invest_button_div {
    bottom: 182px;
    height: 340px;
    padding: 0 8%;
    margin-bottom: -182px;
    display: flex;
}

.invest_button_div .home_title_image {
    
    background-color: white;
    width:45%
}

.invest_button img {
    height: 300px;
}

.invest_card{
    background-color: white;
    width:55%
}

.invest_card p{
    font-size: 18.5px;
    color: #1c2767;
}

.invest_card .card{
    border: none!important;
    box-shadow: none!important
}

.invest_why1 {
    padding: 7% 7% 3% 7% !important;
    text-align: center;
}


.invest_why1 p{
    text-align: left;
}

.invest_why1 .card-title{
    margin-bottom:20px ;
    padding: 0 21%
}

.invest_button .card-title{
    font-size: 33px;
    line-height: 40px;
}


.invest_why{

    padding: 4% 9%!important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.invest_why_grey .invest_desk{
    margin-left: 0;
    margin-right: 10px
}

.invest_desk{
    width: 67%;
    margin-left: 25px
}
/* 
.invest_why .card-title{
    position:absolute;
    top: 22%;
    left: 25.5%;
}

.invest_why_grey .card-title{
    position:absolute;
    top: 22%;
    left: 12%;
} */


.invest_why img {
    height: 215px;
}


.invest_bottomcard {
    padding: 0 18%;
}

.invest_bottomcard button {
    width: 20%;
}

.invest_bottomcard .card-body{
    padding: 2rem
}

}