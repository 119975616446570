.blog-page {
    padding: 48px 12%;
}

.blog-page .header-text-banner {
    margin-bottom: 48px;
}

.blog-content-container {
    column-gap: 5%;
    flex-wrap: wrap;
}

.blog-content-container .filters {
    width: 23%;
}

.blog-content-container .contents,
.text-at-right {
    width: 72%;
}

.filters .date-select {
    box-shadow: 0px 1px 2px 0px #1018280D;
    border: 1px solid #D0D5DD;
    background: #FFFFFF;
    width: 100%;
    height: 40px;
    padding: 10px 14px 10px 34px;
    gap: 0px;
    border-radius: 8px;
    justify-content: space-between;
    margin-bottom: 24px;
    font-family: Museo Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #667085;
}

.react-datepicker-wrapper .clearbutton {
    height: 40px;
    padding-right: 14px;
}

.react-datepicker-wrapper .clearbutton::after {
    background-color: #667085;
}

.react-datepicker-wrapper .react-datepicker__input-container .react-datepicker__calendar-icon {
    width: 15px;
    height: 16px;
    padding: 12px;
    padding-left: 14px;
}

.categories .category {
    padding: 10px 14px;
    color: #667085;
    cursor: pointer;
}

.categories .category.selected {
    color: #EF3E23;
    border-left: 2px solid #EF3E23;
    cursor: default;
}

.blog-page .blog-list {
    row-gap: 48px;
    margin-bottom: 64px;
}

.blog-page .react-datepicker-wrapper {
    width: 100%;
}

.record-box {
    display: block;
    position: relative;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #FFDAD4;
}

.record-box .butn {
    position: absolute;
    top: 3px;
    left: 3px;
    display: block;
    background-color: #EF3E23;
    width: 6px;
    height: 6px;
    border-radius: 3px;
}

.subject-time {
    display: flex;
    height: 30px;
    box-shadow: 0px 1px 2px 0px #1018280D;
    border: 1px solid #D0D5DD;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 4px 10px 4px 4px;
    font-family: Museo Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    margin-right: 12px;
    column-gap: 8px;
    color: #344054;
    align-items: center;
}

.subject-time .subject {
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px 0px #1018280D;
    padding: 2px 6px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    column-gap: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 24px;
}

.subject-time .subject .subject-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    max-height: 18px;
    min-height: 18px;
    line-clamp: 1;
    -webkit-line-clamp: 1;
}

.subject-time .minutes {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    min-width: 60px;
    line-clamp: 1;
    -webkit-line-clamp: 1;
}

.read-panel .date {
    min-width: 82px;
}

.written-published {
    column-gap: 48px;
}

.blog-page .image-details {
    margin-bottom: 96px;
}

.blog-page .pagination .page-item.arrow {
    box-shadow: 0px 1px 2px 0px #1018280D;
    border: 1px solid #D0D5DD;
    padding: 8px 12px;
    border-radius: 8px;
    width: auto;
    height: 36px;
}

.blog-page .pagination .page-link.arrow {
    padding: 0;
    width: auto;
    height: auto;
}

.blog-page .contents .blog-image {
    aspect-ratio: 1.75;
    border-radius: 12px;
    object-fit: cover;
}

.read-type {
    display: flex;
    padding: 4px 10px 4px 4px;
    align-items: center;
    border-radius: 9999px;
    border: 1px solid #FFCBC3;
    background-color: #FFF1EF;
    column-gap: 8px;
    margin-bottom: 16px;
}

.read-type .type {
    border: 1px solid #FFCBC3;
    background-color: #FFFFFF;
    padding: 2px 8px;
    border-radius: 9999px;
}

.single_blog-page .image-details .other-details {
    margin-right: 0 !important;
}

.blog-list .arrow-link {
    column-gap: 16px;
}

.blog-page .heading {
    font-family: 'MuseoSans_500' !important;
    font-size: 30px;
    line-height: 40px;
    font-weight: 500;
}

.single_blog-page .main-description {
    width: 80%;
}

@media screen and (max-width:575px) {
    .blog-page {
        padding: 64px 16px;
    }

    .blog-content-container .filters {
        width: 100%;
    }

    .blog-content-container .contents {
        width: 100%;
    }

    .blog-list .blog-details {
        padding-left: 0;
        padding-right: 0;
    }

    .blog-list .arrow-link {
        column-gap: 10px;
    }

}