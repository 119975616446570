.awards_page {
    padding: 0 12%;
}

.awards_page .head-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 768px;
    max-width: 100%;
    margin: 48px auto;
}

.awards_page .awards {
    margin: 48px 0;
    row-gap: 40px;
}

.fixed-date {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 12px;
}

.fixed-date .fields {
    padding: 24px;
    border-top: 1px solid #FFFFFF4D;
    background: #FFFFFF4D;
}

.image-container {
    margin-bottom: 20px;
}

.image-container img {
    aspect-ratio: 1.32;
    border-radius: 12px;
}


@media screen and (max-width:575px) {
    .awards_page {
        padding: 0 16px;
    }
}