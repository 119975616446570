.press_releases-container {
    padding: 0 12%;
}

.press_releases .page-head-container {
    margin: 48px 0;
}

.carousel {
    column-gap: 2.6%;
    row-gap: 48px;
    padding: 48px 0;
}

.carousel .card-1 {
    width: 31.6%;
}

.carousel .card-1 .card-img {
    aspect-ratio: 1.6;
    border-radius: 12px;
}

.card-1 p,
.card-1 h4 {
    text-align: left;
}

.card-1 .upright-arrow {
    width: 24px;
    object-fit: contain;
}

.card-1 .news_paper {
    font-family: Museo Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
}

.card-1 .title {
    font-family: "MuseoSans_500";
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
}

.card-1 .desc {
    font-family: Museo Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    max-height: 48px;
    min-height: 48px;
    margin-bottom: 24px;
}

.card-1 .date {
    font-family: Museo Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.pagination .page-link {
    font-family: Museo Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #475467;
    border: none;
}

.pagination .page-item.active .page-link {
    background-color: transparent;
    color: #182230;
}

.pagination .page-icon.right-icon {
    transform: rotate(180deg);
}

.pagination-1 ul.pagination {
    display: flex;
    width: 100%;
}

.pagination .previous-arrow {
    display: flex;
    justify-content: start;
}

.pagination-1 {
    border-top: 1px solid #EAECF0;
    padding-top: 20px;
    margin-bottom: 96px;
}

.pagination .arrow .page-icon {
    width: 11.6px;
    height: 11.6px;
}

/*Single Press Page*/

.single_press .main-img {
    aspect-ratio: 1.83;
    margin-top: 64px;
    margin-bottom: 96px;
}

.single_press .news_paper {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
}

.single_press .title {
    font-family: Plus Jakarta Sans;
    font-size: 48px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: -0.02em;
    text-align: center;

}

.single_press .short-desc {
    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
}

.single_press .author {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
}

.single_press .date {
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}

.single-press-container {
    margin: 96px auto;
}

.single-press-container .main-description {
    width: 60%;
}

.single-press-container .main-description img {
    width: 100%;
    object-fit: contain;
    margin: 48px auto;
}

.r-180deg {
    transform: rotate(180deg);
}

@media screen and (max-width:575px) {
    .heading {
        /* styleName: Display md/Semibold; */
        font-family: Inter;
        font-size: 36px;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.02em;
        text-align: left;
    }

    .support-text {
        font-family: Plus Jakarta Sans;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }

    .filter-select-box {
        width: 100%;
        height: 44px;
        padding: 8px;
        border: 1px solid #D0D5DD;
        box-shadow: 0px 1px 2px 0px #1018280D;
        border-radius: 8px;
        background-color: #fff;
        color: #101828;
        /* styleName: Text md/Medium; */
        font-family: Plus Jakarta Sans;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
    }

    .main-sub-head {
        /* styleName: Text xl/Semibold; */
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        text-align: left;
    }

    .press_releases-container {
        padding: 0 16px;
    }

    .card-1 .author-details {
        align-items: center;
        column-gap: 12px;
        height: 40px;
    }

    .card-1 .author-details .author-date {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 40px;
    }

    .card-1 .author-details .author-date .author {
        /* styleName: Text sm/Semibold; */
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
    }

    .card-1 .author-details img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 20px;
    }

    .single-press-container .main-description {
        width: 100%;
    }

    .single_press .main-description p {
        font-family: Museo Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }

    .single_press .main-description h1,
    .single_press .main-description h2,
    .single_press .main-description h3,
    .single_press .main-description h4,
    .single_press .main-description h5,
    .single_press .main-description h6 {
        font-family: Museo Sans;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        text-align: left;
    }

    .single_press .title {
        font-family: Museo Sans;
        font-size: 36px;
        font-weight: 600;
        line-height: 44px;
        letter-spacing: -0.02em;
        text-align: left;
    }

    .single_press .short-desc {
        font-family: Museo Sans;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
    }

    .single-press-container {
        margin: 64px auto;
    }

    .single_press .main-img {
        margin-top: 48px;
        margin-bottom: 64px;
    }

    .press_releases .page-head-container {
        margin: 36px 0;
    }

    .carousel {
        padding: 0;
    }

    .carousel .card-1 {
        width: 100%;
    }

    .card-1 .title {
        /* styleName: Text xl/Semibold; */
        font-family: Inter;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        text-align: left;
    }

    .card-1 .newspaper {
        /* styleName: Text sm/Semibold; */
        font-family: Inter;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-align: left;
        color: #B28F02;
    }

    .card-1 .desc {
        /* styleName: Text md/Regular; */
        font-family: Plus Jakarta Sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
    }

    .card-1 .date {
        /* styleName: Text sm/Regular; */
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
    }

    .mobile-pagination {
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        column-gap: 8px;
        align-items: center;
        margin-top: 48px;
        padding-top: 16px;
        border-top: 1px solid #EAECF0;
        margin-bottom: 64px;
    }

    .mobile-pagination .page-icon {
        width: 36px;
        padding: 12px;
        object-fit: contain;
        border: 1px solid #D0D5DD;
        box-shadow: 0px 1px 2px 0px #1018280D;
        border-radius: 8px;
    }
}