.footer_links {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    padding: 0%;
}

.footer_links a {
    width: 25%;
    margin: 3% 0;
    color: #141744 !important;
}


.footer_logo {
    display: flex;
    justify-content: center;
    margin: 2% 0;
}

.foot:before {
    content: "";
    position: absolute;
    left: 0;
    height: 1px;
    width: 100%;
    border-bottom: 1.5px solid #ded9d9;
}

.foot_link_div .footer_links a div:hover {
    color: #ef3e23
}

@media (min-width: 1001px) and (max-width: 1260px) {
    .footer_links a {
        margin-left: 0 !important;
    }
    .foot .rehbar_logo img{
        height: 65px;
    }
}

@media screen and (min-width: 767px) and (max-width: 1000px) {

    .footer_links a div {
        padding: 0 6px;
        font-size: 15px;

    }

}

@media (min-width: 1001px) {

    .footer_links a div {
        padding: 5px 8px;

    }
}

/* //responsive */
@media (max-width: 500px) {
    .footer_links a:last-child {
        width: 31%
    }

}

@media (min-width: 768px) {
    .foot_link_div {
        width: 83%
    }

    .foot {
        display: flex;
        padding: 0 7%;
        justify-content: space-between;
    }


    .footer_links {

        padding: 0;
        justify-content: flex-end;
    }

    .footer_links a {
        width: auto;
        margin: 3% 0 3% 1%;

    }

}