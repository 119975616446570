.product_page .contact_title{
    padding: 5% 3.35% 3.35% 3.35%;
}
/* .joinus_form_pop div.fund_form_md_sub:last-child{
    width: 100%
} */
.foundation tr{
    text-align: center;
    font-size: 10pt;
    line-height: 0.22in;
}
.career_title{
    margin-top: 1rem
}

 .dotdot{
    color: grey;
    margin-top: -10px;
    margin-bottom: 5px;
}

.benefit_list ul{
    list-style: circle;
    margin-left: -6%;
}
.products_div .card_container{
    bottom: 0
}

.products_div .card_container .card{
    flex-direction: row
}

.products_div .card_container .card-title{
    margin-bottom: 0
}

.products_div{
    padding:4%;
    width: 93%;
    margin: 0 auto;
    background-color: white;
    position: relative;
    bottom: 150px;
    margin-bottom: -150px;
    color: #757474;
    min-height: 100px

}

.products
{
    border-bottom: 0.5px solid #b7b4b4;
    padding-bottom: 5%;
    margin-bottom: 5%;
    background-color: white;
}

.products_head{
    color: #141744;
    font-size: 23px;
}

.features_head{
    color: #EF3E23;
    font-size: 19px;
    font-weight: 600;
    padding: 3% 0 2% 0
}

.benefit_list_item{
    display: flex
}

.benefit_list_item img{
    height: 12px;
}

.benefit_list_text{
    margin-left: 2%;
}

.service_div .features_head{
    padding: 1.75% 0 2% 0;
}


@media (max-width: 500px)
{
    
.product_page .contact_title {
    height: 222px;

}
.products_div .card_container{
    margin-bottom: 0
}
.products_div .card_container .card-title{
    font-size: 15px;
    margin-bottom: 0
}
.benefit_list_item p{
    margin-bottom: 4px!important;
    margin-left: 15px;
    /* text-indent: -1.25em; */

}
.benefit_list{
    margin-left: 3px
}
}
.joinus_form textarea{
    height: 200px!important;
}

/* responsive */
@media (min-width: 767px)
{
.joinus_form form{
    margin: 2% 0 0 0
}
.joinus_form .card-title{
    margin: 2.5% 0 3% 0
}
.product_page .contact_title {
    padding: 2% 7.5%;

}
.products {
    padding-top: 2%;
    padding-bottom: 2%;
    margin-bottom: 1%}

.products_div {
    bottom: 200px;
    margin-bottom: -180px;
    width: 85%;
    padding:1% 2% 0% 2%;
}

.benefit_list_item p{
    margin-bottom: 0!important;
    margin-left: 15px;
    /* text-indent: -1em; */

}


}