.mailchimpnew input{
    border: none;
    border-bottom: 1px solid grey;
    padding: 0 15%;
    text-align: center;
    margin-bottom: 10px;
    margin: 10px 15%;
}

.mailchimpnew #g-recaptcha{
    width: auto
}

.investpage .mailchimpnew{
    margin: 5% 15%;
}

.mailchimpnew{
    box-shadow: 0 0 5px 1px rgba(193,192,192,.75)!important;
    padding: 5%;
    margin-bottom: 5%;
}

.clear input:hover{
box-shadow: 0px 0px 1px 1px #EF3E23!important;
}

.clear input{
    background-color: #ef3e23;
    color: white;
    border-bottom: none;
    margin:0 auto;
    padding: 0;
    width: 40%;
    border-radius: 3px;
    box-shadow: none;
    height: 33px;
}


.mc-field-group{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column
}

.mc-field-group .card-title,.mc-field-group .card-text{
    text-align: center
}

.clear{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0.75%
}

@media (max-width:500px){
    .investpage .mailchimpnew{
        margin: 5% 2%
    }
}