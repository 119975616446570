.events-container {
    padding: 60px 12%;
}

.carousel-one {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.6%;
    row-gap: 48px;
}

.carousel-one .single-card {
    width: 31%;
}

.carousel-one .single-card .image {
    aspect-ratio: 1.6;
    border-radius: 8px;
}

.single-card .title {
    font-family: Museo Sans;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
}

.events .pagination-1 {
    margin-top: 64px;
}

.open-arrow {
    width: 10px;
    height: 10px;
    margin-top: 7px;
    margin-left: 16px;
}

.tabs {
    display: flex;
    height: 56px;
    width: fit-content;
    border: 1px solid #EAECF0;
    background: #F9FAFB;
    padding: 6px;
    border-radius: 8px;
    column-gap: 8px;
}

.tabs .single-tab {
    width: fit-content;
    height: 44px;
    padding: 8px 12px;
    border-radius: 6px;
    background-color: white;
    cursor: pointer;
}

.tabs .single-tab.active {
    box-shadow: 0px 1px 2px 0px #1018280F;
    box-shadow: 0px 1px 3px 0px #1018281A;
}

.venue-date {
    column-gap: 48px;
}

.main-description h2,
.main-description h3,
.main-description h4,
.main-description h5,
.main-description h6 {
    font-family: Museo Sans;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #101828;
}

.main-description p {
    color: #475467;
    font-family: Museo Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
}

.main-description {
    width: 60%;
}

.main-description img {
    margin: 48px 0;
    max-width: 100%;
}

@media screen and (max-width:575px) {
    .carousel-one .single-card {
        width: 100%;
    }

    .events-container {
        padding: 32px 16px;
    }

    .events-container .heading {
        font-family: Inter;
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
    }

    .carousel-one {
        row-gap: 32px;
    }

    .single-card .title {
        font-family: Museo Sans;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        text-align: left;
    }

    .mobile-pagination .page-icon.right-icon {
        transform: rotate(180deg);
    }

    .tab-select-box {
        height: 44px;
        background: #FFFFFF;
        border: 1px solid #D0D5DD;
        box-shadow: 0px 1px 2px 0px #1018280D;
        border-radius: 8px;
        padding: 10px 42px 10px 14px;
        background-repeat: no-repeat;
        background-position: 95% 19px;
        background-size: 10px 5px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .venue-date {
        column-gap: 0;
    }

    .main-description {
        width: 100%;
    }

    .main-description h2,
    .main-description h3,
    .main-description h4,
    .main-description h5,
    .main-description h6 {
        font-family: Museo Sans;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        text-align: left;
        color: #101828;
    }

    .main-description p {
        font-size: 16px;
        line-height: 24px;
    }

    .main-description img {
        margin: 40px 0;
    }
}