.App {
  text-align: center;
}
.navbar-expand-md .navbar-nav a div{
  font-family: 'MuseoSans_300' !important;
  font-weight: 600
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.pr_menu{
  position: absolute;
    height: auto;
    width: auto;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
    margin-top: 10px;
    z-index: 999;
    box-shadow: 0px 0px 5px 1px rgba(193, 192, 192, 0.75)!important;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.repo {
  display: flex;
  flex-wrap: wrap;
  min-height: 250px;
  padding: 3% 1% 0 1%;
  margin: 0% 8%;
  margin-top: -210px;
  background-color: white;
}

.cust-landing .siteload{
  min-height: 400px
}


.repo a{
  transition: transform .3s;
  width: 25%;
  justify-content: center
}

.repo-block{
  word-break: break-word;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}

.repo .card-title{
  margin-bottom: 0.2rem
}

.repo img{
  width: 80px;
  height: auto;
}

.repo-desc{
  text-align: center;
  color: #4A4A4A;
  font-size: 14px
}

.repodiv {
  position: relative;
  min-height: 450px;
}

.repo a:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.repo a:hover .card-title {
  color: #ef3e23
}



@media (max-width: 599px){
  .video .land-img{
    min-height: 200px;
  }
  .repo{
      flex-direction: column;
      padding: 5% 1%;
      margin: 0% 4%;
      margin-top: -170px;
  }
  .repo-block{
      width: 100%;
      margin-bottom: 20px;
      padding: 0 10px
  }
  .repo a{
      width: 100%;
      justify-content: center
  }
  .repodiv {
      min-height: 0;
  }
  
}



@media (max-width:900px) and (min-width:600px) {
  .repo{
      padding: 3% 1% 0 1%;
      margin: 0% 8%;
      margin-top: -180px
  }
  .repo img {
      width: 65px;
  }

  .repo-desc {
      
      font-size: 12px;
  }
}


@media (max-width: 330px){
  .repo{
    
      margin-top: -190px;
  }

  .repo img {
      width: 75px;
  }
  
}
@media (max-width:760px) and (min-width:601px) {


.repodiv .contact_title {
  padding: 1.85% 9.35% 4.35% 7.35%;

}
}

.landtopdiv{
  background-repeat: no-repeat!important;
    background-position: top center!important;
    padding: 5%;
    background-size: cover!important;
}

.vid-text {
  color: white;
  font-size: 25px;
  font-weight: 600;
}

.fund-div{
  width: 40%;
}

.cust-landing .cc_code{
  width: 60px;
}

.cust-landing button{
  background-color: #ef3e23;
  color: white;
}
.cust-landing .fund-div{
  width: 90%;
}

.cust-landing .landing-form{
  width: 49%;
}
.landing-top{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.landing-top.left-form{
  flex-direction: row-reverse;
}

.cust-landing .video{
  width: 49%;
}

.cust-landing .testimonials{
  position: static;
  margin-bottom: 0;
}

.cust-landing .head{
  padding-bottom: 0;
}

.cust-landing .landing-top .fund_form_md{
  justify-content: flex-end;
}
.cust-landing .landing-top.left-form .fund_form_md{
  justify-content: space-between;
}
@media (max-width:500px){

  .cust-landing .landing-top{
    flex-direction: column;
  }
  .cust-landing .landing-top.left-form{
    flex-direction: column-reverse;
  }
  main.cust-landing .video{
    width: 100%;
    margin-bottom: 10px;
  }
  main.cust-landing .fund-div {
    width: 100%;
  } 
  main.cust-landing .landing-form {
    width: 100%;
    margin-bottom: 10px;
  }
  .cust-landing .homtestimonial{
    margin: 15px 7.75%;
  }
  .cust-landing .homepagediv .rec_fund {
    padding: 0% 0!important;
    margin-bottom: 10%;
}
}

.land-img{
  background-size: contain!important;
  min-height: 300px;
  background-repeat: no-repeat!important;
  width: 100%;
}