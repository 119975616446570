.newContainer {
    padding: 0 12%;
}

.head-banner-container {
    width: 1024px;
    max-width: 100%;
}

.carousel-one {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2.6%;
}

.carousel-one .card-one {
    width: 31.6%;
}

.pagination .page-link {
    font-family: Museo Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #475467;
    border: none;
}

.pagination .page-item.active .page-link {
    background-color: transparent;
    color: #182230;
}

.pagination .page-icon.right-icon {
    transform: rotate(180deg);
}

.pagination-1 ul.pagination {
    display: flex;
    width: 100%;
}

.pagination .previous-arrow {
    display: flex;
    justify-content: start;
    margin-right: auto;
}

.pagination .next-arrow {
    display: flex;
    justify-content: end;
    margin-left: auto;
}

.pagination .page-item .page-link {
    display: grid;
    place-items: center;
    width: 40px;
    height: 40px;
}

.pagination-1 {
    border-top: 1px solid #EAECF0;
    padding-top: 20px;
    margin-bottom: 96px;
}

.pagination .arrow .page-icon {
    width: 11.6px;
    height: 11.6px;
}

.tabs {
    display: flex;
    height: 56px;
    width: fit-content;
    border: 1px solid #EAECF0;
    background: #F9FAFB;
    padding: 6px;
    border-radius: 8px;
    column-gap: 8px;
}

.tabs .single-tab {
    width: fit-content;
    height: 44px;
    padding: 8px 12px;
    border-radius: 6px;
    background-color: white;
    cursor: pointer;
}

.tabs .single-tab.active {
    box-shadow: 0px 1px 2px 0px #1018280F;
    box-shadow: 0px 1px 3px 0px #1018281A;
}

.other-details {
    column-gap: 12px;
}

.other-details .detail-box {
    height: 40px;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px 0px #1018280D;
    padding: 10px 14px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    column-gap: 4px;
    /* styleName: Text sm/Semibold; */
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #344054;
    cursor: pointer;
    width: auto;
}

.other-details .detail-box a {
    color: #344054;
}

.other-details .detail-box img {
    object-fit: contain;
    width: auto !important;
    height: 20px;
}

.breadcrumbs {
    align-items: center;
    column-gap: 12px;
    margin-bottom: 48px;
    padding: 6px 0;
}

.breadcrumbs img {
    width: 16px;
    height: 16px;
    padding: 4px 6px;
}

.grey-dot {
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: #C4C4C4;
}

.openinner {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.loader-orange {
    animation-duration: 2.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #EF3E23;
    background: linear-gradient(to right, #808080 2%, #B0B0B0 18%, #808080 33%);
    background-size: 1200px 100%;
    border-radius: 12px;
}

@-webkit-keyframes shimmer {
    0% {
        background-position: -100% 0;
    }

    100% {
        background-position: 100% 0;
    }
}

@keyframes shimmer {
    0% {
        background-position: -1200px 0;
    }

    100% {
        background-position: 1200px 0;
    }
}

@media screen and (max-width:575px) {
    .mobile-pagination {
        font-family: Plus Jakarta Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        column-gap: 8px;
        align-items: center;
        margin-top: 48px;
        padding-top: 16px;
        border-top: 1px solid #EAECF0;
        margin-bottom: 64px;
    }

    .mobile-pagination .page-icon {
        width: 36px;
        padding: 12px;
        object-fit: contain;
        border: 1px solid #D0D5DD;
        box-shadow: 0px 1px 2px 0px #1018280D;
        border-radius: 8px;
    }

    .tab-select-box {
        height: 44px;
        background: #FFFFFF;
        border: 1px solid #D0D5DD;
        box-shadow: 0px 1px 2px 0px #1018280D;
        border-radius: 8px;
        padding: 10px 42px 10px 14px;
        background-repeat: no-repeat;
        background-position: 95% 19px;
        background-size: 10px 5px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .carousel-one .card-one {
        width: 100%;
    }

    .newContainer {
        padding: 0 16px;
    }

    .other-details {
        flex-wrap: wrap;
        row-gap: 12px;
    }
}